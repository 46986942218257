import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../features/user";
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../Constants';
import '../styles.css';
import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../features/errorDialog";
import {handleApiErrorMessage} from "../Helper";

export default function Device(props) {
  const { t } = useTranslation();

  const initialLineDeviceValue = {
    imei: null,
    model: null,
    brand: null
  };
  const [lineDevice, setLineDevice] = useState(initialLineDeviceValue);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(loading){
      return () => {};
    }
    refreshDeviceData();
  }, [props.line.id]);

  const refreshDeviceData = () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    axios.post(API_URL + '/getLineDevice', {customer_id: props.customer.id, line_id: props.line.id}, {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("lines"))){
          setLineDevice(data.lines);
          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
    });
  };

  const render = (lineDevice) => {
    return(
    <>
      <div>{t('Device IMEI')} {"IMEI"}:</div>
      <div>{lineDevice.imei}</div>
      <div>
        <strong>{t('Device Brand')}:</strong> {lineDevice.brand}
      </div>
      <div>
        <strong>{t('Device Model')}:</strong> {lineDevice.model}
      </div>
    </>);
  }

return <div className='device-wrapper'>
    {lineDevice.imei !== null ?
      (props.hasOwnProperty("render") ?
        props.render(lineDevice) :
        render(lineDevice)
      )
      : ''}
  </div>
}
