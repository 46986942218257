import React from 'react';
import WatchSystem from '../../WatchSystem/WatchSystem';

export default function ClockSystem(props) {
  return (
  <div className='customer-watch-wrapper mobile'>
      <WatchSystem 
        isDesktop={false}
        entityType={props.entityType} 
        lineId={(props.entityType === "line" ? props.line.id : null)} 
        customerId={props.customer.id}
      ></WatchSystem> 
  </div>)
}
