import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
import { logout } from "../../features/user";
import { Backdrop, CircularProgress} from '@mui/material';
import { API_URL } from '../../Constants';
import axios from "axios";
import cookie from "js-cookie";


export function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
      let  headers = {
        token: cookie.get('token')
      }

      axios.get(API_URL + '/logout', {headers});
      dispatch(logout());
      navigate("/", { replace: true });
    }, []);
  
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
  };