import React, {useState, useEffect} from 'react';
import { CALL_TYPE_IN, CALL_TYPE_OUT, STATUS_BLOCKED, CALL_TYPE_ALL, STATUS_OPEN, CUSTOMER_VIEW_CUSTOMERS_LIST} from '../../../Constants';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import MobileDataGrid from "../../MobileDataGrid";
import Switch from "../../Switch";
import Select from "../../Select";


export default function BlockCategory(props) {
  const { t } = useTranslation();
  const [visibleEditExpansionIds, setVisibleEditExpansionIds] = useState([]);

  const columns = [
    { field: 'number', headerName: t('Number')},
    { field: 'active', type: 'boolean', headerName: t('Is Active'),
    valueGetter: (row) => {
      return (
        row.active === 1 ? 
        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9.5L7 15.5L21.5 1" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg> :
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2L2 12M12 12L2 2" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    }
  },
    { field: 'status', headerName: t('Status'), 
      valueGetter: (row) => {return (row.status === STATUS_BLOCKED ? t("Blocked") : t("Open"))},
      flex: 1,  sortable:false
    },
    { field: 'call_type', headerName: t('Call Type'), 
      valueGetter: (row) => {
        let label = null;
        if(row.call_type === CALL_TYPE_OUT){
          label = t("Outgoing");
        }
        if(row.call_type === CALL_TYPE_IN){
          label = t("Incoming");
        }
        if(row.call_type === CALL_TYPE_ALL){
          label = t("Both");
        }
        return label;
      }
    },
    { field: 'note', headerName: t('Note')}
  ];

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
        if(e.target.closest(".body") !== null 
        || e.target.closest(".header") !== null
        || e.target.closest(".expansions-wrapper") !== null
        ){
        return;
      }else{
        setVisibleEditExpansionIds([]);
      }
    })
  }, []);

  const handleEditClick = (e, cellValues) => {
    if(visibleEditExpansionIds.includes(cellValues.id)){
      let newVisibleEditExpansionIds = [...visibleEditExpansionIds];
      newVisibleEditExpansionIds.splice(newVisibleEditExpansionIds.indexOf(cellValues.id), 1);
      setVisibleEditExpansionIds(newVisibleEditExpansionIds);
    }else{
      setVisibleEditExpansionIds([...visibleEditExpansionIds, cellValues.id]);
    }
    e.stopPropagation();
  }

  const handleChangeEditValue = (newValue, changedField, cellValues) => {
    if(newValue === cellValues[changedField]){
      return;
    }
    const newField = {[changedField] : newValue};
    props.handleEditBlockSubmit({...cellValues, ...newField});
  }

  const renderRowExpansion = (cellValues) => {
    return <>
    <div className={'block-actions'+(!visibleEditExpansionIds.includes(cellValues.id) ? "" : " hidden")}>
      <div className='item phone'>{cellValues.number}</div>
      <div className='item actions'>
        <div className='edit'
          onClick={(e) => {
            handleEditClick(e, cellValues);
          }}>
          <div className='text'>{t('Edit')}</div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="11.7369" cy="11.6784" rx="11.7369" ry="11.5925" fill="black"/>
          <path className="external" d="M6.32031 15.3306V17.6992H8.71849L15.7915 10.7133L13.3933 8.34464L6.32031 15.3306ZM17.6461 8.88153C17.8955 8.63519 17.8955 8.23726 17.6461 7.99092L16.1496 6.51288C15.9002 6.26654 15.4973 6.26654 15.2479 6.51288L14.0776 7.66878L16.4758 10.0374L17.6461 8.88153Z" fill="#FFC700"/>
          </svg>
        </div>
        <div className='delete'
          onClick={(e) => {
            props.displayDeletePopup(cellValues);
          }}
        >
          <div className='text'>{t('Delete')}</div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.9739 11.6706C22.9739 17.791 17.9488 22.7631 11.7369 22.7631C5.52509 22.7631 0.5 17.791 0.5 11.6706C0.5 5.55021 5.52509 0.578125 11.7369 0.578125C17.9488 0.578125 22.9739 5.55021 22.9739 11.6706Z" fill="black" stroke="black"/>
          <path className="external" d="M16 8L8 16M16 16L8 8" stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div 
      className={'edit-form'+(visibleEditExpansionIds.includes(cellValues.id) ? "" : " hidden")} 
      data-block-id={cellValues.id}
      >
      <div className='line'>
          <div className='input-wrapper'>
            <input type="text" 
              className='underline'
              name="number"
              value={cellValues.number}
              disabled="disabled"
              />
          </div>
          <div className='extra-items'>
            <div className='extra-item-wrapper'>
              <Switch 
                type="boolean"
                name="active"
                value={cellValues.active}
                mainValue={1}
                handleClick={(newValue) => {
                  handleChangeEditValue(newValue, "active", cellValues);
                }}
                />
            </div>
            <div className='extra-item-wrapper'>
              <Switch 
                type="text"
                name="status"
                values={{
                  [STATUS_BLOCKED]: t("Blocked"),
                  [STATUS_OPEN]: t("Open") 
                }}
                value={cellValues.status}
                mainValue={STATUS_OPEN}
                handleClick={(newValue) => {
                  handleChangeEditValue(newValue, "status", cellValues);
                }}
                />
            </div>
            <div className='extra-item-wrapper'>
              <Select values={{
                [CALL_TYPE_IN]: t("Incoming"),
                [CALL_TYPE_OUT]: t("Outgoing"),
                [CALL_TYPE_ALL]: t("Both")
              }} 
                value={cellValues.call_type}
                handleClick={(newValue) => {
                  handleChangeEditValue(newValue, "call_type", cellValues);
                }}
                />
            </div>
          </div>
      </div>
      <div className='note-wrapper'>
        <div className='text'>{t("Note")}:</div>
        <input type="text" 
              className='underline'
              name="number"
              data-id={cellValues.id}
              defaultValue={cellValues.note}
              />
        <img src='design-images/save.svg'
          onClick={(e) => {
            const newValue = document.querySelector(".note-wrapper input[data-id='"+cellValues.id+"']").value;
            handleChangeEditValue(newValue, "note", cellValues);
          }}
        />
      </div>
    </div>
    </>
  }

  return (  
    <div className="customer-blocks-information-view"
    >
      <div className='title'>
        <div className='back' onClick={() => {props.setCustomerView(CUSTOMER_VIEW_CUSTOMERS_LIST);}}>
            <img src='design-images/arrow-around.svg' className='arrow-around' />
            <div className='text'>{t("Back to customers management")}</div>
        </div>
        <div className='text main'>{t("Block List Management")}</div>
        <div className="notice-message">
          <div className='text'>
            {t("Please note that the configurations on customer and line level will precede the community configurations.")}
          </div>
          <div className='sign'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 9.81818C19 14.6711 14.9879 18.6364 10 18.6364C5.01206 18.6364 1 14.6711 1 9.81818C1 4.96525 5.01206 1 10 1C14.9879 1 19 4.96525 19 9.81818Z" stroke="#A44200" strokeWidth="2"/>
            <path d="M10 5.89062V10.7997" stroke="#A44200" strokeWidth="2" strokeLinecap="round"/>
            <ellipse cx="10" cy="13.7474" rx="1" ry="0.981818" fill="#A44200"/>
          </svg>
          </div>
        </div>
        <div className='switch-wrapper'>
          {(
            Number.isInteger(props.customer.id) && Number.isInteger(props.line.id) ? 
            <>
            <div className='switch-type line'
              onClick={props.handleSwitchClick}>
              <svg className="circle" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8.5" cy="8.5" r="8.5" fill="white"/>
              </svg>
              <div className='text'>{t("line")}</div>
            </div>
            <div className='switch-entity'
              onClick={
                () => {
                  (props.isLinesListVisible) ? props.setIsLinesListVisible(false) : props.setIsLinesListVisible(true)
                }}
            >
              <div className='text'>{props.line.use_name} | {props.line.number}</div>
              <div className={'arrow arrow-'+(props.isLinesListVisible ? "up":"down")}></div>
              <div className={"lines-list"+(props.isLinesListVisible ? "" : " hidden")}>
                {props.getLinesList()}
              </div>
            </div>
            </> :
            <>
            <div className='switch-type customer'
            onClick={props.handleSwitchClick}
            >
              <div className='text'>{t("customer")}</div>
              <svg className="circle" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8.5" cy="8.5" r="8.5" fill="white"/>
              </svg>
            </div>
            <div className='switch-entity'>
              <div className='text'>{props.customer.name} | {props.customer.phone}</div>
            </div>
            </>
          )}
        </div>
        <div className='controls-wrapper'>
          <div className='button-add' onClick={props.handleAddRow}>+ {t("Add a row")}</div>
          <div className='search-wrapper'>
              <input 
                type="text" 
                className="search" 
                id="search-value" 
                placeholder={t("Search")}
                onChange={(e)=>{props.changeSearchFilters(e, "number")}} />
              <img src="design-images/search.svg" className='button' />
          </div>
        </div>
    </div>
      <MobileDataGrid 
      rowCount={props.rowCount}
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      mainColumn="number"
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={(newPage) => {
        props.setPage(newPage);
      }}
      onPageSizeChange={(newPageSize) => {
        props.setPageSize(newPageSize);
      }}
      visibleExtraColumn="status"
      swapPagesColumns={[
        ["active","status","call_type"],
        ["note"]
      ]}
      renderRowExpansion={renderRowExpansion}
      expandedRowIds={props.expandedRowIds}
      setExpandedRowIds={props.setExpandedRowIds}
      />
    </div>
  );
}
