import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { isOpen: false, title: "Error", text: "Error"};

export const errorDialogSlice = createSlice({
  name: "errorDialog",
  initialState: () => {
      return {value: initialStateValue};
  },
  reducers: {
    showErrorDialog: (state, action) => {
        state.value.title = action.payload.title;
        state.value.text = action.payload.text;
        state.value.page = (action.payload.page ? action.payload.page : 'all');
        state.value.isOpen = true;
    },

    hideErrorDialog: (state) => {
      state.value.isOpen = false;
      state.value = initialStateValue;
    },
  },
});

export const { showErrorDialog, hideErrorDialog } = errorDialogSlice.actions;

export default errorDialogSlice.reducer;