import React from 'react';
import WatchSystem from '../../WatchSystem/WatchSystem';
import { CUSTOMER_VIEW_CUSTOMER } from '../../../Constants';
import { useTranslation } from 'react-i18next';

export default function ClockSystem(props) {
  const { t } = useTranslation();

  return (
  <div className='customer-watch-wrapper'>
    <div className='controls-wrapper'>
        <div className='controls'>
          <div className='text'>
            <strong>{t("Block List Management")}</strong>
            <span>{t("for")}:</span>
          </div>
          <div className='switch-entity'>
            {(
              props.entityType === "line" ?
              <div className='text'>{props.line.use_name} | {props.line.number}</div> :
              <div className='text'>{props.customer.name} | {props.customer.phone}</div>
            )}
          </div>
        </div>
        <div className='search'>
          <div className='back'
            onClick={(e) => {
              props.setCustomerView(CUSTOMER_VIEW_CUSTOMER);
            }}
          >
            <div className='text'>{t("Back to the customer information")}</div>
            <img src='design-images/arrow-left.svg' className='arrow-left' />
          </div>
        </div>
      </div>
      <WatchSystem 
        isDesktop={true}
        entityType={props.entityType} 
        lineId={(props.entityType === "line" ? props.line.id : null)} 
        customerId={props.customer.id}
      ></WatchSystem> 
  </div>)
}
