import React from 'react';
import '../../styles.css';
import CustomersGridWrapperDesktop from './Desktop/GridWrapper';
import CustomersGridWrapperMobile from './Mobile/GridWrapper';
import { Backdrop, CircularProgress} from '@mui/material';


export default function CustomersGridWrapper(props) {
  return (  
    <>
    {(
      props.isDesktop ?
      <CustomersGridWrapperDesktop
      changeSearchParameter={props.changeSearchParameter}
      searchParameter={props.searchParameter}
      page={props.page}
      rows={props.rows}
      pageSize={props.pageSize}
      rowCount={props.rowCount}
      setPage={props.setPage}
      setPageSize={props.setPageSize}
      setSelectedCustomer={props.setSelectedCustomer}
      setCustomerView={props.setCustomerView}
      handleDeleteCustomer={props.handleDeleteCustomer}
      refreshCustomersData={props.refreshCustomersData}
      setPopupPosition={props.setPopupPosition}
      popupPosition={props.popupPosition}
      /> :
      <CustomersGridWrapperMobile 
      changeSearchParameter={props.changeSearchParameter}
      searchParameter={props.searchParameter}
      page={props.page}
      rows={props.rows}
      pageSize={props.pageSize}
      rowCount={props.rowCount}
      setPage={props.setPage}
      setPageSize={props.setPageSize}
      setSelectedCustomer={props.setSelectedCustomer}
      setCustomerView={props.setCustomerView}
      handleDeleteCustomer={props.handleDeleteCustomer}
      refreshCustomersData={props.refreshCustomersData}
      />
    )}
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
}
