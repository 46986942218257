import React, {useEffect, useState} from 'react';
import '../styles.css';


export default function Select(props) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if(typeof props.isFocus === 'undefined') {
      return () => {};
    }
    setIsVisible(props.isFocus);
    
  }, [props.isFocus]);

  const getSelectOptions = () => {
    let selectOptions = [];
    for(let itemKey in props.values){
      selectOptions.push(
        <div
        key={itemKey}
        onClick={(e) => {
          props.handleClick(itemKey, e);
          setIsVisible(false);
        }}
        className={(itemKey === props.value ? "selected" : "")}
        >
          {props.values[itemKey]}
        </div>
      );
    }
    return selectOptions;
  }


  return (  
    <div className='select'
      style={props.style}
      onClick={(e) => {
        if(props.selectClicked) {
          props.selectClicked(!isVisible);
        }
        setIsVisible(!isVisible);
        e.stopPropagation();
      }}
    >
      <div className='text'>{props.values[props.value]}</div>
      <div className={'arrow arrow-'+(isVisible ? "up":"down")}></div>
      <div className={"lines-list"+(isVisible ? "" : " hidden")}>
        <div className='lines-wrapper'>
          {getSelectOptions()}
        </div>
        <div className='arrow arrow-up'></div>
      </div>
    </div>
  );
}
