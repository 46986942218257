import React from 'react';


export default function InnerDialog(props) {
  return (
    props.open === true ? 
    <div className={'inner-dialog '+props.className}
    >
      <div className='inner-nested-dialog'
      style={{width: "calc(100% - 210px)", position: "absolute", top: props.popupPosition ?? 0 }}
      >
      {props.children}
      </div>
    </div> : ''
  );
}
