import { createSlice } from "@reduxjs/toolkit";
import cookie from "js-cookie";



const initialStateValue = { isLoggedIn: false};

export const userSlice = createSlice({
  name: "user",
  initialState: () => {
    if(cookie.get('token')){
      return {value: { isLoggedIn: true, community_name: cookie.get('community_name')}};
    }else{
      return {value: initialStateValue};
    }
  },
  reducers: {
    login: (state, action) => {
        state.value.isLoggedIn = true;
        state.value.community_name = action.payload.community_name;
        cookie.set('token', action.payload.token);
        cookie.set('community_name', action.payload.community_name);
        cookie.set('is_logged_in', '1');
        cookie.set('smsWatchSystemAllow', action.payload.smsWatchSystemAllow);
    },

    logout: (state) => {
      state.value = initialStateValue;
      cookie.remove('token');
      cookie.remove('is_logged_in');
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;