import React, {useState} from 'react';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import DesktopDataGrid from '../../DesktopDataGrid';
import Select from '../../Select';
import SelectWrapper from '../../SelectWrapper';


export default function CategoriesGridWrapperDesktop(props) {
  const { t } = useTranslation();

  const inputNameRef = React.useRef(null);

  const columns = [
    { field: 'name', headerName: t('Name')},
    { field: 'verified', headerName: t('Verified category'),  
      renderCell: (cellValues) => {
        return (
          cellValues.valid === 1 ?
          <div className="check-wrapper">
            <div className="check"></div>
          </div>
          : ''
        );
      }
    },
    { field: 'public', type: 'boolean', headerName: t('Is Public'),
      valueGetter: (row) => {
        return (
          row.public === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'create_date', headerName: t('Created at')},
    { field: 'isOwner', type: 'boolean', headerName: t('Is Owner'),
      valueGetter: (row) => {
        return (
          row.isOwner === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'myJoin', type: 'boolean', headerName: t('Is Joined'),
      valueGetter: (row) => {
        return (
          row.myJoin === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'active_setting', headerName: t('Active numbers in the list')
    },
    { 
      field: 'view', headerName: '',
      renderCell: (cellValues) => {
        return (
          <svg 
          className='show-customer button'
          onClick={(e) => props.handleViewRow(cellValues)}
          width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
          <path d="M5 15.0011C10.5556 6.99902 19.4444 7.00024 25 15.0011C19.4444 22.9996 10.5556 22.9996 5 15.0011Z" fill="white"/>
          <circle cx="15" cy="15" r="3.25" stroke="#FFC700" strokeWidth="1.5"/>
          </svg>
        );
      }
    },
    { 
      field: 'delete', headerName: '',
      renderCell: (cellValues) => {
        return (
          cellValues.isOwner === 1 && cellValues.id !== 0 ?
            <svg 
            className='delete-customer button' onClick={() => props.handleDeleteRow(cellValues)} 
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
            <path d="M20 10L10 20M20 20L10 10" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> : ''

          );
      }
    }
  ];

  return (  
    <div className="categories-wrapper">
      <div className='top-wrapper'>
        <div className={'search-form'+(props.isSearchFormVisible ? '' :' hidden')}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="close-button"
            onClick={() => {props.setIsSearchFormVisible(false)}}
          >
            <img src="design-images/close-black.svg" 
              className="close"
            />
           </div>
           <div className="focus-input"
            onClick={() => {inputNameRef.current.focus()}}
           >
            <input type="text" 
              ref={inputNameRef}
              className='underline'
              placeholder={t("Search category")}
              name="name"
              onChange={(e) => {
                props.changeSearchFilters(e.target.value, "name");
              }}
            />
          </div>
          <SelectWrapper
            name ={t("Is Owner")}
            values={{
              [false]: t("Show All"),
              [true]: t("Yes")
            }} 
            value={props.searchInput.onlyOwner}
            handleClick={(newValue) => {
              props.changeSearchFilters(newValue, "onlyOwner");
            }}
          />

          <SelectWrapper 
            name={t("Is Joined")}

            values={{
              [false]: t("Show All"),
              [true]: t("Yes")
            }} 
            value={props.searchInput.onlyMy}
            handleClick={(newValue) => {
              props.changeSearchFilters(newValue, "onlyMy");
            }}
          />

          <SelectWrapper 
            name={t("Only Verified Categories")}
            values={{
              [false]: t("Show All"),
              [true]: t("Yes")
            }} 
            value={props.searchInput.onlyValid}
            handleClick={(newValue) => {
              props.changeSearchFilters(newValue, "onlyValid");
            }}
          />

          <div className="search-button"
            onClick={props.handleSearchSubmit}
          >
            <img src="design-images/search.svg" 
              className="button"
            />
          </div>
        </div>
        <div className='title-wrapper'>
          <div className='title'>{t('Block categories')}</div>
          <div className='search-label'
              onClick={() => {props.setIsSearchFormVisible(true)}}>
            <div className='text'>{t("Search category")}</div>
            <img src="design-images/search-gray.svg" 
              className="button"
               />
          </div>
        </div>
      </div>
      <div className='grid-wrapper'>
        <DesktopDataGrid 
          rowCount={props.rowCount}
          rows={props.rows}
          columns={columns}
          loading={props.loading}
          page={props.page}
          pageSize={props.pageSize}
          onPageChange={(newPage) => {
            props.setPage(newPage);
          }}
          onPageSizeChange={(newPageSize) => {
            props.setPageSize(newPageSize);
          }}
          setSelectionIds={props.setSelectionIds}
          selectionIds={props.selectionIds}
          expandedRowIds={props.expandedRowIds}
          setExpandedRowIds={props.setExpandedRowIds}
          />
            <div className='controls'>
              <div className='button-add' 
                onClick={
                  () => {
                    props.handleAddRow();
                  }}
                >+ {t("Add a row")}
              </div>
              {(props.selectionIds.length > 0 && props.isUserBelongsToAllSelectedCategories) ?
                <div className='button-remove-belongs' 
                  onClick={
                    () => {
                      props.handleRemoveBelongsRows();
                    }}
                  >- {t("Remove belongs to category")}
                </div> :
                <div className='button-add-belongs' 
                onClick={
                  () => {
                    props.handleAddBelongsRows();
                  }}
                >+ {t("Add belongs to category")}
              </div>
            }
            </div>
      </div>
    </div>
  );
}
