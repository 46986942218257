import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_URL, API_OK, STATUS_BLOCKED, API_EXCEPTION, DEFAULT_ERROR_TEXT, ALL, CALL_TYPE_ALL, STATUS_OPEN} from '../../Constants';
import {Dialog} from '@mui/material';
import '../../styles.css';
import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import {handleApiErrorMessage} from "../../Helper";
import CategoryDesktop from './Desktop/Category';
import CategoryMobile from './Mobile/Category';

export const IMPORT_HOVER_UPLOAD = "upload";
export const IMPORT_HOVER_DOWNLOAD = "download";

export default function Category(props) {
  const { t } = useTranslation();
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [editBlockValues, setEditBlockValues] = useState({});

  const [isAddFormVisible, setIsAddFormVisible] = useState(false);

  const initalSelectedCategoryValue = {
    id: null,
    name: null,
    note: null,
    public: 1,
    privacy_level: 0,
    isOwner: 1,
    myJoin: 0,
    valid: 0,
    active_setting: 0
  };
  const [selectedCategory, setSelectedCategory] = useState(initalSelectedCategoryValue);

  const initialSearchInput = {
    number : "",
    active: ALL,
    call_type: ALL,
    status: ALL
  };
  const [searchInput, setSearchInput] = useState(initialSearchInput);
  
  const initialAddValue = {
    number : "",
    active: "1",
    call_type: CALL_TYPE_ALL,
    status: STATUS_OPEN,
    note: ""
  };
  const [addValue, setAddValue] = useState(initialAddValue);
  const [lineNumbersToHighlight, setLineNumbersToHighlight] = useState([]);

  const [selectionIds, setSelectionIds] = useState([]);
  const [singleRowIdToDelete, setSingleRowIdToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(props.loading){
      return () => {};
    }

    if(props.hasOwnProperty("category") 
      && props.category.hasOwnProperty("id")
      && Number.isInteger(props.category.id)){
        const selectedCategoryValueFromProps = {
          id: props.category.id,
          name: props.category.name,
          note: props.category.note,
          public: props.category.public,
          isOwner: props.category.isOwner,
          myJoin: props.category.myJoin,
          valid: props.category.valid,
          privacy_level: props.category.privacy_level
        };
    
        setSelectedCategory(selectedCategoryValueFromProps);
    }
  }, []);

  useEffect(() => {
    if(props.loading){
      return () => {};
    }
    if(!Number.isInteger(selectedCategory.id)){
      return () => {};
    }
    const requestSource = refreshBlockedData(); 
    return () => {
      requestSource.cancel();
    };
  }, [page, pageSize]);

  useEffect(() => {
    if(!Number.isInteger(selectedCategory.id)){
      return () => {};
    }
    const requestSource = refreshBlockedData(); 
    return () => {
      requestSource.cancel();
    };
  }, [selectedCategory.id]);

  useEffect(() => {
    removeNonExistingSelectionIds();
  }, [rows]);

  const removeNonExistingSelectionIds = () => {
    let newSelectionIds = [];
    for(let index=0; index<rows.length; index++){
      if(selectionIds.includes(rows[index].id)){
        newSelectionIds.push(rows[index].id);
      }
    }
    setSelectionIds(newSelectionIds);
  }

  const refreshBlockedData = (pageToStart = null) => {
    if(pageToStart === null){
      pageToStart = page;
    }
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    props.setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/getBlockList';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/getBlockList';
    }

    const requestSource = axios.CancelToken.source();
    headers.cancelToken = requestSource.token;

    axios.post(
      apiUrl, 
      {
        searchStr:searchInput.number, 
        active: searchInput.active === ALL ? ALL : Number(searchInput.active), 
        status: searchInput.status, 
        call_type: searchInput.call_type, 
        start: pageToStart*pageSize, 
        limit: pageSize
      }, 
      {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("results") && data.hasOwnProperty("totalBlocks"))){
          setRows(data.results);
          setRowCount(data.totalBlocks);
          props.setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          props.setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        props.setLoading(false);
    });

    return requestSource;
  };

  const toggleExpandRow = (rowId) => {
    if(expandedRowIds.includes(rowId)){
      let newExpandedRowIds = [...expandedRowIds];
      newExpandedRowIds.splice(newExpandedRowIds.indexOf(rowId), 1);
      setExpandedRowIds(newExpandedRowIds);
    }else{
      setExpandedRowIds([...expandedRowIds, rowId]);
    }
  }

  const handleDeleteRow = (cellValues) => {
    setSingleRowIdToDelete(cellValues.id);
    setDeleteDialogIsOpen(true);
  };
  
  const handleDeleteRowsSubmit = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let rowIdsToDelete = [];
    if(selectionIds.length > 0){
      rowIdsToDelete = selectionIds;
    }else if(singleRowIdToDelete !== null){
      rowIdsToDelete = [singleRowIdToDelete];
    }

    if(rowIdsToDelete.length === 0){
      alert(t('No lines selected'));
      return;
    }else{
      let nonRemovedRows = rows.filter((row) => (!rowIdsToDelete.includes(row.id)));
      if(nonRemovedRows.length === rows.length){
        alert(t('No lines selected'));
        return;
      }
    }
    
    props.setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }
    
    const data = {
      blockIds: rowIdsToDelete
    };

    let apiUrl = API_URL + '/deleteBlocks';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/deleteBlocks';
    }

    axios.post(apiUrl, data, {headers})
    .then(
        (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          refreshBlockedData();
          setSelectionIds([]);
        }else{
          if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
        props.setLoading(false);
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);

        props.setLoading(false);
    });
  };

  const addLinesData = (linesDataToAdd) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/addBlockList';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/addBlockList';
    }

    axios.post(apiUrl, linesDataToAdd, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          await refreshBlockedData();
          setLineNumbersToHighlight([addValue.number]);
          setTimeout(() => {
            setLineNumbersToHighlight([]);
          }, 1000);
          props.setLoading(false);
        }else{
          if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          props.setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        props.setLoading(false);
    });
  };

  const handleAddLineSubmit = () => {
    if(addValue.number === ""){
      return;
    }
    props.setLoading(true);

    addLinesData({"numbers": [addValue]});
    setIsAddFormVisible(false);
  }

  const handleAddClick = () => {
    setAddValue(initialAddValue);
    setIsAddFormVisible(true);
  }

  const onImportRows = () => {
    document.getElementById("import_file").click();
  }

  const onImportRowsSubmit = (file) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    const data = new FormData();
    data.append('file', file);
    
    let  headers = {
      token: cookie.get('token')
    }

    props.setLoading(true);

    let apiUrl = API_URL + '/importExcel';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/importExcel';
    }

    axios.post(apiUrl, data, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          await refreshBlockedData();
          props.setLoading(false);
        }else{
          if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          props.setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        props.setLoading(false);
    });
  }

  const handleEditLineSubmit = (blockId) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    props.setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    const data = {
      blockId: blockId,
      newData: {
        note: editBlockValues[blockId].note,
        active: editBlockValues[blockId].active,
        call_type: editBlockValues[blockId].call_type,
        status: editBlockValues[blockId].status
      }
    };

    let apiUrl = API_URL + '/editBlock';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/editBlock';
    }

    axios.post(apiUrl, data, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          await refreshBlockedData();
          toggleExpandRow(blockId)
        }else{
          if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
        props.setLoading(false);
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        props.setLoading(false);
    });
  }

  const changeSearchFilters = (newValue, fieldName) => {
    let newSearchInput = {
      number : searchInput.number,
      active: searchInput.active,
      call_type: searchInput.call_type,
      status: searchInput.status
    };
    newSearchInput[fieldName] = newValue;
    setSearchInput(newSearchInput);
  };

  const handleSearchSubmit = (e) => {
    refreshBlockedData(0);
  };

  const handleChangeEditValue = (newValue, changedField, cellValues) => {
    if(newValue === cellValues[changedField]){
      return;
    }
    const newField = {[changedField] : newValue};
    const newValues = {...cellValues, ...newField};
    setEditBlockValues({...editBlockValues, [cellValues.id] : newValues});
  }

  const isValidValue = (value, type='') => {
    if(type === "number"){
      if(!/^[0-9\*]*$/.test(value)){
          return false;
      }
    }
    return true;
}

  return (  
    <>
    {(
      props.isDesktop ? 
      <CategoryDesktop 
      handleAddClick={handleAddClick}
      page={page}
      setPage={setPage}
      rows={rows}
      setRows={setRows}
      rowCount={rowCount}
      setRowCount={setRowCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      handleEditLineSubmit={handleEditLineSubmit}
      searchInput={searchInput}
      changeSearchFilters={changeSearchFilters}
      handleChangeEditValue={handleChangeEditValue}
      handleSearchSubmit={handleSearchSubmit}
      selectedCategory={selectedCategory}
      handleDeleteRow={handleDeleteRow}
      onImportRows={onImportRows}
      toggleExpandRow={toggleExpandRow}
      lineNumbersToHighlight={lineNumbersToHighlight}
      editBlockValues={editBlockValues}
      setDeleteDialogIsOpen={setDeleteDialogIsOpen}
      setLoading={props.setLoading}
      selectionIds={selectionIds}
      setSelectionIds={setSelectionIds}
      expandedRowIds={expandedRowIds}
      setExpandedRowIds={setExpandedRowIds}
      viewCategoriesList={props.viewCategoriesList}
      isValidValue={isValidValue}
      addValue={addValue}
      setAddValue={setAddValue}
      handleAddLineSubmit={handleAddLineSubmit}
      isAddFormVisible={isAddFormVisible}
      setIsAddFormVisible={setIsAddFormVisible}
      /> :
      <CategoryMobile 
      handleAddClick={handleAddClick}
      page={page}
      setPage={setPage}
      rows={rows}
      setRows={setRows}
      rowCount={rowCount}
      setRowCount={setRowCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      handleEditLineSubmit={handleEditLineSubmit}
      searchInput={searchInput}
      changeSearchFilters={changeSearchFilters}
      handleChangeEditValue={handleChangeEditValue}
      handleSearchSubmit={handleSearchSubmit}
      selectedCategory={selectedCategory}
      handleDeleteRow={handleDeleteRow}
      onImportRows={onImportRows}
      toggleExpandRow={toggleExpandRow}
      lineNumbersToHighlight={lineNumbersToHighlight}
      editBlockValues={editBlockValues}
      setDeleteDialogIsOpen={setDeleteDialogIsOpen}
      setLoading={props.setLoading}
      selectionIds={selectionIds}
      setSelectionIds={setSelectionIds}
      expandedRowIds={expandedRowIds}
      setExpandedRowIds={setExpandedRowIds}
      viewCategoriesList={props.viewCategoriesList}
      isValidValue={isValidValue}
      addValue={addValue}
      setAddValue={setAddValue}
      handleAddLineSubmit={handleAddLineSubmit}
      isAddFormVisible={isAddFormVisible}
      setIsAddFormVisible={setIsAddFormVisible}
      />
    )}
      <input id="import_file" type="file" name="import_file" className='hidden'
      onInput={(e) => {
        const file = e.target.files[0];
        e.target.value = null;
        onImportRowsSubmit(file);
      }}
      />
    <Dialog open={deleteDialogIsOpen} 
      onClose={() => {
        setSingleRowIdToDelete(null);
        setDeleteDialogIsOpen(false);
      }}
      className="confirm-dialog popup"
        >
        <div className='title'>
          {(
            selectionIds.length > 1 ?
            <div className='text'>{t("Do you want to delete the records?")}</div> :
            <div className='text'>{t("Do you want to delete the record?")}</div>
          )}
        </div>
        <div className='content'>
          <div className='controls'>
              <div className='proceed'
              onClick={
                () => {
                  setDeleteDialogIsOpen(false);
                  props.setLoading(true);
                  handleDeleteRowsSubmit();
                }}
              >{t("Yes")}</div>
              <div className='cancel'
                onClick={() => {setDeleteDialogIsOpen(false)}}
              >{t("Cancel")}</div>
            </div>
        </div>
    </Dialog>
    </>
  );
}
