import { t } from 'i18next';
import React, {useState, useEffect} from 'react';

export default function MobileDataGrid(props) {
  const [expandedRowTops, setExpandedRowTops] = useState({});
  const [currentSwapPage, setCurrentSwapPage] = useState("0");

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
        if(
          e.target.closest("body") === null 
        || e.target.closest(".body") !== null 
        || e.target.closest(".header") !== null
        || e.target.closest(".expansions-wrapper") !== null
        || e.target.closest(".expansion") !== null
        || e.target.closest("div[role='presentation']") !== null
        ){
        return;
      }else{
        props.setExpandedRowIds([]);
      }
    })
  }, []);

  useEffect(() => {
    let newExpandedRowTops = {};
    let element = null;
    for(let index=0; index<props.expandedRowIds.length; index++){
      element = document.querySelector(".row[data-id='"+props.expandedRowIds[index]+"']");
      if(element === null){
        newExpandedRowTops[props.expandedRowIds[index]] = 0;
      }else{
        newExpandedRowTops[props.expandedRowIds[index]] = element.offsetTop-element.offsetHeight;
      }
    }
    setExpandedRowTops(newExpandedRowTops);
  }, [props.expandedRowIds]);

  const getSwapColumnsForSinglePages =() => {
    let swapPagesColumns = [];
    let currentSwapColumn = null;
    for(let index=0; index<props.columns.length; index++){
      if(props.columns[index].field !== props.mainColumn){
        currentSwapColumn = [
          props.columns[index].field
        ];
        swapPagesColumns.push(currentSwapColumn);
      }
    }
    return swapPagesColumns;
  };
  const swapPagesColumns = (props.hasOwnProperty("swapPagesColumns") ? 
    props.swapPagesColumns :
    getSwapColumnsForSinglePages());


  const getGridColumns = () => {
    let columnsObject = props.columns.reduce((acc, column) => ({...acc,[column.field]:column}),{});
    let columns = [];
    for(let index=0; index<swapPagesColumns[currentSwapPage].length; index++){
      columns.push(
      <div 
        className='column' 
          key={swapPagesColumns[currentSwapPage][index]}
          data-field={swapPagesColumns[currentSwapPage][index]}
          style={{width: (100/(swapPagesColumns[currentSwapPage].length))+"%"}}
          >
          {columnsObject[swapPagesColumns[currentSwapPage][index]].headerName}
        </div>
      );
    }
    return columns;
  }

  
  const getMainColumn = () => {
    let columns = [];
    for (let index = 0; index < props.columns.length; index++) {
      if(props.columns[index].field !== props.mainColumn){
            continue;
          }
      columns.push(
        <div 
        className='column' 
        key={props.columns[index].field}
        data-field={props.columns[index].field}>
          {props.columns[index].headerName}
        </div>);
    }
    return columns;
  }
  
  const getMainColumnRows = () => {
    let rows = [];
    for (let index = 0; index < props.rows.length; index++) {
      const row = getMainColumnRow(props.rows[index], props.mainColumn);
      rows.push(
        <div 
        className={'row ' + (props.expandedRowIds.includes(props.rows[index].id) ? 'expanded' : '')}
        key={props.rows[index].id}
        onClick={
          () =>{
            if(props.hasOwnProperty("renderRowExpansion")
            ){
              toggleExpandRow(props.rows[index]);
            }
          }}
        >
          {row}
        </div>);
    }
    return rows;
  }
  
  const getGridRows = () => {
    let rows = [];
    for (let index = 0; index < props.rows.length; index++) {
      rows.push(
        <div 
        className={'row ' + (props.expandedRowIds.includes(props.rows[index].id) ? 'expanded' : '')}
        data-id={props.rows[index].id}
        key={props.rows[index].id}
        >
          {getRow(props.rows[index], swapPagesColumns[currentSwapPage])}
        </div>);
    }
    return rows;
  }
  
  const getExpansions = () => {
    let rows = [];
    for (let index = 0; index < props.rows.length; index++) {
      if(props.expandedRowIds.includes(props.rows[index].id)){
        rows.push(
          <div className='expansion' 
            key={"expansion-"+props.rows[index].id}
            data-id={props.rows[index].id}
            style={{
              top: expandedRowTops[props.rows[index].id]+"px"
            }}
            >
              {props.renderRowExpansion(props.rows[index])}
        </div>
         );
      }
    }
    return rows;
  }

  const handleSwapClick = async (e) => {
    let pageToShow = e.target.getAttribute("data-page");
    setCurrentSwapPage(pageToShow);
  }
  
  const getSwapButtons = () => {
    let swapButtons = [];
    for (let index = 0; index < swapPagesColumns.length; index++) {
      swapButtons.push(
        <div 
        className={"button "+((""+index) === currentSwapPage ? "current" : "")} 
        key={"swap-"+index}
        data-page={index}
        onClick={handleSwapClick}
        >
        </div>);
    }
    return swapButtons;
  }

  const getRow = (rowData) => {
    let columnsObject = props.columns.reduce((acc, column) => ({...acc,[column.field]:column}),{});
    let row = [];
    for (let rowKey in columnsObject) {
      if(!swapPagesColumns[currentSwapPage].includes(rowKey)){
        continue;
      }
      if(!rowData.hasOwnProperty(rowKey)){
        continue;
      }
      row.push(
        <div 
          className='item' 
          key={rowKey+"-"+rowData.id}
          onClick={
            () =>{
              if(props.hasOwnProperty("renderRowExpansion")){
                toggleExpandRow(rowData);
              }
            }}
          style={{width: (100/(swapPagesColumns[currentSwapPage].length))+"%"}}
          >
            <div className='content'>
            {(
              columnsObject[rowKey].hasOwnProperty("valueGetter") ? 
              columnsObject[rowKey].valueGetter(rowData) :
              rowData[rowKey]
            )}
            </div>
        </div>);
    }
    return row;
  }
  
  const getMainColumnRow = (rowData, mainColumnField) => {
    return <div 
      className='item' 
      >
        {rowData[mainColumnField]}
      </div>;
  }

  const toggleExpandRow = (cellValues) => {
    if(props.expandedRowIds.includes(cellValues.id)){
      let newExpandedRowIds = [...props.expandedRowIds];
      newExpandedRowIds.splice(newExpandedRowIds.indexOf(cellValues.id), 1);
      props.setExpandedRowIds(newExpandedRowIds);
    }else{
      props.setExpandedRowIds([...props.expandedRowIds, cellValues.id]);
    }
  }
 
  return (  
    <div className='mobile-grid-wrapper'>
      <div className='mobile-grid'>
        <div className='main-column'>
          <div className='header'>
            {getMainColumn()}
            </div>
            <div className='body'>
            {getMainColumnRows()}
            </div>
          </div>
          <div className='extra-columns'>
            <div className='header'>
            {getGridColumns()}
            </div>
            <div className='body'>
            {(
              props.rows.length === 0 ?
              <div className='no-rows'>{t("No rows found")}</div> :
              getGridRows()
            )}
            </div>
          </div>
          {(
            props.hasOwnProperty("renderRowExpansion") ?
            <div className='expansions-wrapper'>
              {getExpansions()}
            </div> : ''
          )}
          
      </div>
      <div className='controls'>
        <div className='pagination'>
          {(
            props.page > 0 ?
            <div className='page-arrow prev'
              onClick={() => {props.onPageChange(props.page-1)}}
            >{"<"}</div> : ''
          )}
          <div className='page-info'>
            {t("{{first}}-{{last}} out of {{total}}", {
              first: props.page*props.pageSize+1,
              last: Math.min((props.page+1)*props.pageSize, props.rows.length),
              total: props.rows.length
            })}
          </div>
          {(
            props.page < Math.floor(props.rows.length / props.pageSize) ?
            <div className='page-arrow next'
              onClick={() => {props.onPageChange(props.page+1)}}
            >{">"}</div> : ''
          )}
        </div>
        <div className='columns-swap'>
          {getSwapButtons()}
        </div>
      </div>
    </div>
  );
}
