import {React, useEffect, useState} from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard';
import CategoriesManagement from './components/pages/CategoriesManagement';
import CustomersManagement from './components/pages/CustomersManagement';
import ClockSystem from './components/pages/ClockSystem';
import AntiSpam from './components/pages/AntiSpam';
import {Login} from './components/pages/Login';
import {Logout} from './components/pages/Logout';
import AuthRoute from './components/routes/AuthRoute';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button} from '@mui/material';
import { hideErrorDialog } from "./features/errorDialog";
import { LoginByToken } from './components/pages/LoginByToken';
import { APP_NAME } from './Constants';


function App(props) {
  const user = useSelector((state) => state.user.value);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorDialog = useSelector((state) => state.errorDialog.value);

  const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleErrorDialogClose = () => {
    dispatch(hideErrorDialog());
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    document.body.classList.add(APP_NAME);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
{/*       <Helmet>
          <title>{t("Kehilot Interface")}</title>
      </Helmet> */}
      <Router>
        {user.isLoggedIn ? 
        <Navbar /> :
        ''
        }
        <div className='main-wrapper'>
        <Switch>
          <Route path='/' exact element={<AuthRoute isLoggedIn={user.isLoggedIn}><Dashboard /></AuthRoute>} />
          <Route path='/dashboard' element={<AuthRoute isLoggedIn={user.isLoggedIn}><Dashboard /></AuthRoute>} />
          <Route path='/blocks-management' element={<AuthRoute isLoggedIn={user.isLoggedIn}><CategoriesManagement isDesktop={windowSize.innerWidth > 960}/></AuthRoute>} />
          <Route path='/customers-management' element={<AuthRoute isLoggedIn={user.isLoggedIn}><CustomersManagement isDesktop={windowSize.innerWidth > 960} /></AuthRoute>} />
          <Route path='/clock-system' element={<AuthRoute isLoggedIn={user.isLoggedIn}><ClockSystem isDesktop={windowSize.innerWidth > 960} /></AuthRoute>} />
          <Route path='/anti-spam' element={<AuthRoute isLoggedIn={user.isLoggedIn}><AntiSpam isDesktop={windowSize.innerWidth > 960} /></AuthRoute>} />
          <Route path='/login' element={<Login />} />
          <Route path='/login-by-token' element={<LoginByToken />} />
          <Route path='/logout' element={<AuthRoute isLoggedIn={user.isLoggedIn}><Logout /></AuthRoute>} />
        </Switch>
        </div>
      </Router>
      <Dialog open={errorDialog.isOpen && errorDialog.page === "all"} onClose={handleErrorDialogClose}>
        <DialogTitle>{t(errorDialog.title)}</DialogTitle>
        <DialogContent>
        <Typography component="div">{t(errorDialog.text)}</Typography>
        </DialogContent>
        <DialogActions> 
          <Button  variant="contained" onClick={handleErrorDialogClose} size="large">{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default App;
