import React from 'react';


export default function Chip(props) {
  return (<div className='chip'>
    <div className='text'>{props.label}</div>
    <img 
      className='delete'
      onClick={props.onDelete}
      src='design-images/close-grey-on-grey.svg' />
  </div>
  );
}
