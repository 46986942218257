import React from 'react';
import { CUSTOMER_VIEW_BLOCK, CUSTOMER_VIEW_CUSTOMERS_LIST, CUSTOMER_VIEW_WATCH } from '../../../Constants';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import Device from '../../Device';
import {Button, Box} from '@mui/material';
import DesktopDataGrid from '../../DesktopDataGrid';
import { useState } from 'react';

export default function Customer(props) {
  const { t } = useTranslation();

  const [isSettingsListDisplayed, setIsSettingsListDisplayed] = useState(false);
  const [currentlyOpenedSettingsForLine, setCurrentlyOpenedSettingsForLine] = useState(null);

  const columns = [
    { field: 'use_name', headerName: t('Line User Name')},
    { field: 'number', headerName: t('Number')},
    { field: 'create_date', headerName: t('Created at')},
    //{ field: 'last_call', headerName: t('Last Call')},
    { 
      field: 'last_call', 
      headerName: t('Last Call'),
      renderCell: (params) => {
        //console.log(params);
        return (
          <div style={{ color: ((new Date() - new Date(params.last_call)) / (1000 * 60 * 60 * 24)) > 5 && localStorage.getItem('show_red_inactive_lines') === "true" ? "red" : "black"}}>
            {params.last_call}
          </div>
        );
      }
    },
    {
      field: 'view_details', headerName: t('Connected to Device'),
      renderCell: (cellValues, gridFunctions) => {
        return (
          <svg 
          className='show-device button'
          onClick={(e) => gridFunctions.toggleExpandRow(cellValues)}
          width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="black"/>
          <path d="M5 15.0011C10.5556 6.99902 19.4444 7.00024 25 15.0011C19.4444 22.9996 10.5556 22.9996 5 15.0011Z" fill="white"/>
          <circle cx="15" cy="15" r="3.25" stroke="black" strokeWidth="1.5"/>
          </svg>
        );
      }
    },
    { 
      field: 'view_watch_profile', headerName: t('Settings'),
      renderCell: (cellValues) => {
        return (
          <div className={'settings button-wrapper'+(currentlyOpenedSettingsForLine === cellValues.id ? ' opened' : '')}>
            <div className='button'
            onClick={(e) => {
              if(currentlyOpenedSettingsForLine !== cellValues.id){
                setCurrentlyOpenedSettingsForLine(cellValues.id);
              }else{
                setCurrentlyOpenedSettingsForLine(null);
              }
              
              e.stopPropagation();
            }}
            >
              <svg 
              width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="14.5" cy="14.5" r="14.5" fill="#161616"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.501 5H16.6134L17.0166 8.02451C17.8346 8.31874 18.5845 8.75587 19.236 9.30577L22.0589 8.14193L24.1151 11.7033L21.6992 13.5635C21.7739 13.9808 21.8128 14.4105 21.8128 14.8492C21.8128 15.287 21.774 15.7157 21.6997 16.1321L24.1159 17.9924L22.0597 21.5538L19.2384 20.3907C18.5866 20.9413 17.8364 21.379 17.0178 21.6736L16.6149 24.6955H12.5025L12.0992 21.6709C11.2824 21.376 10.5336 20.9384 9.88314 20.3884L7.05692 21.5536L5.00075 17.9922L7.42359 16.1267C7.34988 15.712 7.31141 15.2851 7.31141 14.8492C7.31141 14.4126 7.35002 13.9849 7.424 13.5695L5 11.7031L7.05617 8.14171L9.88543 9.30816C10.5349 8.75946 11.2821 8.32288 12.0972 8.02826L12.501 5ZM10.8856 14.8492C10.8856 16.8797 12.5316 18.5258 14.5621 18.5258C16.5926 18.5258 18.2387 16.8797 18.2387 14.8492C18.2387 12.8187 16.5926 11.1727 14.5621 11.1727C12.5316 11.1727 10.8856 12.8187 10.8856 14.8492Z" fill="#F5F5F5"/>
              </svg>
            </div>
            <div className='list'>
              <div className='item'
                onClick={(e) => {
                  props.setSelectedLine(cellValues);
                  props.setCustomerView(CUSTOMER_VIEW_BLOCK);
                  e.stopPropagation();
                }}
              >
                <div className='text'>{t('Block Lists')}</div>
                <img src="design-images/blocked-yellow-black.svg" />
              </div>
              <div className='item'
                onClick={(e) => {
                  props.setSelectedLine(cellValues);
                  props.setCustomerView(CUSTOMER_VIEW_WATCH);
                  e.stopPropagation();
                }}
              >
                <div className='text'>{t('Watch Configurations')}</div>
                <img src="design-images/watch-yellow-black.svg" />
              </div>
            </div>
          </div>
        );
      }
    }
  ];

  const renderRowExpansion = (cellValues) => {
    return <Device customer={props.customer} line={cellValues} />
  };

  return (  
    <div className="customer-information-popup"
      onClick={(e) => {
        setIsSettingsListDisplayed(false);
        setCurrentlyOpenedSettingsForLine(null);
      }}
    >
      <div className='title'>
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
          <div className='top-controls'>
            <div className='text main'>{t("Customer Information")}</div>
            <div className='back' onClick={(e) => {props.setCustomerView(CUSTOMER_VIEW_CUSTOMERS_LIST)}}>
              <div className='text'>{t("Back to customers management")}</div>
              <img src='design-images/arrow-left.svg' className='arrow-left' />
            </div>
          </div>
          <div className='bottom-controls'>
            <div className='customer-info'>
              <div className='item' style={{width: "25%"}}>{t('Name')} | {props.customer.name}</div>
              <div className='item' style={{width: "25%"}}>{t('Number')} | {props.customer.phone}</div>
              <div className='item' style={{width: "50%"}}>{t('Last Updated at')} | {props.customer.update_date}</div>
              <div className='item' style={{width: "50%", color: (((new Date() - new Date(props.customer.last_out_call)) / (1000 * 60 * 60 * 24)) > 5 && localStorage.getItem('show_red_inactive_lines') === "true") ? "red" : "black"}}>{t('Last Call')} | {props.customer.last_out_call}</div>
            </div>
            <div className='settings-dropdown'>
              <div className='main'
                onClick={(e) => {
                  setIsSettingsListDisplayed(true);
                  e.stopPropagation();
                }}
              >
                <div className='text'>{t("Customer settings")}</div>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.6652 19L6.89012 19L6.51993 16.2235C5.77029 15.9542 5.08296 15.5542 4.48543 15.051L1.89072 16.1208L0.00319725 12.8515L2.21997 11.1447C2.15063 10.7595 2.11443 10.3628 2.11443 9.95759C2.11443 9.55653 2.1499 9.16376 2.21787 8.78223L-0.00165072 7.0733L1.88588 3.80401L4.47619 4.87194C5.07541 4.36545 5.76537 3.96295 6.51821 3.69228L6.88786 0.919904L10.6629 0.919904L11.0331 3.69602C11.7837 3.96732 12.4716 4.36976 13.0691 4.87568L15.6623 3.80655L17.5498 7.07584L15.3243 8.78942C15.3914 9.16869 15.4265 9.55905 15.4265 9.95759C15.4265 10.3572 15.3912 10.7486 15.3237 11.1289L17.5547 12.8466L15.6672 16.1159L13.0649 15.043C12.4692 15.5466 11.7838 15.9474 11.0361 16.2181L10.6652 19ZM12.1454 9.95759C12.1454 8.09364 10.6344 6.58261 8.77044 6.58261C6.90649 6.58261 5.39546 8.09364 5.39546 9.95759C5.39546 11.8215 6.90649 13.3326 8.77044 13.3326C10.6344 13.3326 12.1454 11.8215 12.1454 9.95759Z" fill="black"/>
                </svg>
              </div>
              {(
                isSettingsListDisplayed ? 
                <div className='list'>
                  <div className='item first'
                    onClick={(e) => {
                      setIsSettingsListDisplayed(false);
                      e.stopPropagation();
                    }}
                  >
                    <div className='text'>{t('Customer settings')}</div>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.6652 19L6.89012 19L6.51993 16.2235C5.77029 15.9542 5.08296 15.5542 4.48543 15.051L1.89072 16.1208L0.00319725 12.8515L2.21997 11.1447C2.15063 10.7595 2.11443 10.3628 2.11443 9.95759C2.11443 9.55653 2.1499 9.16376 2.21787 8.78223L-0.00165072 7.0733L1.88588 3.80401L4.47619 4.87194C5.07541 4.36545 5.76537 3.96295 6.51821 3.69228L6.88786 0.919904L10.6629 0.919904L11.0331 3.69602C11.7837 3.96732 12.4716 4.36976 13.0691 4.87568L15.6623 3.80655L17.5498 7.07584L15.3243 8.78942C15.3914 9.16869 15.4265 9.55905 15.4265 9.95759C15.4265 10.3572 15.3912 10.7486 15.3237 11.1289L17.5547 12.8466L15.6672 16.1159L13.0649 15.043C12.4692 15.5466 11.7838 15.9474 11.0361 16.2181L10.6652 19ZM12.1454 9.95759C12.1454 8.09364 10.6344 6.58261 8.77044 6.58261C6.90649 6.58261 5.39546 8.09364 5.39546 9.95759C5.39546 11.8215 6.90649 13.3326 8.77044 13.3326C10.6344 13.3326 12.1454 11.8215 12.1454 9.95759Z" fill="black"/>
                    </svg>
                  </div>
                  <div className='item'
                    onClick={(e) => {
                      props.resetSelectedLine();
                      props.setCustomerView(CUSTOMER_VIEW_BLOCK);
                      e.stopPropagation();
                    }}
                  >
                    <div className='text'>{t('Block Lists')}</div>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.9275 8.99888C13.9275 11.7209 11.7209 13.9275 8.99888 13.9275C8.00373 13.9275 7.07747 13.6325 6.30274 13.1253L12.8192 5.88479C13.512 6.73366 13.9275 7.81775 13.9275 8.99888ZM5.18677 12.123L11.7058 4.87958C10.9289 4.368 9.99866 4.07031 8.99888 4.07031C6.27691 4.07031 4.07031 6.27691 4.07031 8.99888C4.07031 10.1847 4.48907 11.2726 5.18677 12.123ZM15.4275 8.99888C15.4275 12.5493 12.5493 15.4275 8.99888 15.4275C5.44848 15.4275 2.57031 12.5493 2.57031 8.99888C2.57031 5.44848 5.44848 2.57031 8.99888 2.57031C12.5493 2.57031 15.4275 5.44848 15.4275 8.99888Z" fill="black"/>
                    </svg>
                  </div>
                  <div className='item'
                    onClick={(e) => {
                      props.resetSelectedLine();
                      props.setCustomerView(CUSTOMER_VIEW_WATCH);
                      setIsSettingsListDisplayed(false);
                      e.stopPropagation();
                    }}
                  >
                    <div className='text'>{t('Watch Configurations')}</div>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="white"/>
                      <circle cx="8.99888" cy="8.99888" r="6.42857" fill="black"/>
                      <path d="M9 5.57031V8.99888L12.8571 10.7132" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                  </div>
                </div> : ''
              )}
            </div>
          </div>
        </Box>
        <div className='top-round-corners'></div>
      </div>
      <DesktopDataGrid 
      rowCount={props.rowCount}
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={(newPage) => {
        props.setPage(newPage);
      }}
      onPageSizeChange={(newPageSize) => {
        props.setPageSize(newPageSize);
      }}
      renderExpansion={renderRowExpansion}
      expandedRowIds={props.expandedRowIds}
      setExpandedRowIds={props.setExpandedRowIds}
      />
    </div>
  );
}
