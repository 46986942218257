import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import { BLOCK_LIST_MODE_WHITELIST, BLOCK_LIST_MODE_BLACKLIST, APP_NAME, APP_NAME_SIMKEHILOT } from '../../Constants';
import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
import {getCommunityGeneralInfo} from "../../Helper"
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";

export default function Dashboard() {
  const [stats, setStats] = useState({activeLines:null, activeCustomers:null, blockListMode: {incoming: null, outcoming: null}, name: null, note: null});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
    
  useEffect(() => {
    const fetchData = async () => {
      const communityGeneralInfo = await getCommunityGeneralInfo(cookie.get('token'), t, dispatch, showErrorDialog, logout, navigate);
      setStats(communityGeneralInfo);
    }
    fetchData();
  }, []);

  return <div className='dashboard-wrapper'>
      <div className='side'>
        <div className='stats'>
        <div className='number'>{stats.activeCustomers}</div>
          <div className='text'>
            <span className='type'>{t('Customers')}</span>
            <span>{(APP_NAME === APP_NAME_SIMKEHILOT ? t('in the community') : '')}</span>
          </div>
        </div>
        <div className='stats'>
          <div className='number'>{stats.activeLines}</div>
          <div className='text'>
            <span className='type'>{t('Lines')}</span>
            <span>{(APP_NAME === APP_NAME_SIMKEHILOT ? t('in the community') : '')}</span>
          </div>
        </div>
      </div>
      <div className='main'>
        <div className='description'>{stats.note}</div>
        <div className='stats-wrapper'>
          <div className='stats'>
          <div className='number'>{stats.activeCustomers}</div>
            <div className='text'>
              <span className='type'>{t('Customers')}</span>
              <span>{(APP_NAME === APP_NAME_SIMKEHILOT ? t('in the community') : '')}</span>
            </div>
          </div>
          <div className='stats'>
            <div className='number'>{stats.activeLines}</div>
            <div className='text'>
              <span className='type'>{t('Lines')}</span>
              <span>{(APP_NAME === APP_NAME_SIMKEHILOT ? t('in the community') : '')}</span>
            </div>
          </div>
        </div>
        <div className='blocking-policy-wrapper'>
          <div className='title'>{(APP_NAME === APP_NAME_SIMKEHILOT ? t('Community blocking policy') : t('Blocking policy'))}</div>
          <div className='blocking-policy'>
            <div className='type'>{t('Incoming calls')}</div>
            {(
              stats.blockListMode.incoming === BLOCK_LIST_MODE_BLACKLIST ?
              <div className="switch blacklist">
                <div className='text'>{t(BLOCK_LIST_MODE_BLACKLIST)}</div>
                <img src='design-images/white-circle.svg' className='circle' />
              </div> :
              <div className="switch whitelist">
                <img src='design-images/white-circle.svg' className='circle' />
                <div className='text'>{t(BLOCK_LIST_MODE_WHITELIST)}</div>
              </div>
            )}
          </div>
          <div className='blocking-policy'>
            <div className='type'>{t('Outcoming calls')}</div>
            {(
              stats.blockListMode.outcoming === BLOCK_LIST_MODE_BLACKLIST ?
              <div className="switch blacklist">
                <div className='text'>{t(BLOCK_LIST_MODE_BLACKLIST)}</div>
                <img src='design-images/white-circle.svg' className='circle' />
              </div> :
              <div className="switch whitelist">
                <img src='design-images/white-circle.svg' className='circle' />
                <div className='text'>{t(BLOCK_LIST_MODE_WHITELIST)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>;
}
