import React from 'react';
import { CALL_TYPE_IN, CALL_TYPE_OUT, STATUS_BLOCKED, CALL_TYPE_ALL, CUSTOMER_VIEW_CUSTOMER} from '../../../Constants';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import DesktopDataGrid from "../../DesktopDataGrid";


export default function BlockCategory(props) {
  const { t } = useTranslation();

  const columns = [
    { field: 'number', headerName: t('Number')},
    { field: 'status', headerName: t('Status'), 
      valueGetter: (row) => {return (row.status === STATUS_BLOCKED ? t("Blocked") : t("Open"))},
      flex: 1,  sortable:false
    },
    { field: 'active', type: 'boolean', headerName: t('Is Active'),
      valueGetter: (row) => {
        return (
          row.active === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'call_type', headerName: t('Call Type'), 
      valueGetter: (row) => {
        let label = null;
        if(row.call_type === CALL_TYPE_OUT){
          label = t("Outgoing");
        }
        if(row.call_type === CALL_TYPE_IN){
          label = t("Incoming");
        }
        if(row.call_type === CALL_TYPE_ALL){
          label = t("Both");
        }
        return label;
      }
    },
    { field: 'note', headerName: t('Note')},
    { 
      field: 'edit', headerName: "",
      renderCell: (cellValues) => {
        return (
            <svg 
            onClick={(e) => props.displayEditPopup(cellValues)}
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
            <path d="M7.96094 19.1551V22.1313H10.9817L19.8911 13.3536L16.8703 10.3774L7.96094 19.1551ZM22.2272 11.052C22.5413 10.7425 22.5413 10.2425 22.2272 9.93301L20.3422 8.07589C20.028 7.76637 19.5205 7.76637 19.2064 8.07589L17.7322 9.52825L20.753 12.5044L22.2272 11.052Z" fill="white"/>
            </svg>

        );
      }
    },
    { 
      field: 'delete', headerName: "",
      renderCell: (cellValues) => {
        return (
          <svg 
          onClick={(e) => props.handleDeleteRow(cellValues)}
          width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
          <path d="M20 10L10 20M20 20L10 10" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      }
    }
  ];

  return (  
    <>
      <div className="customer-blocks-popup"
      >
        <div className="notice-message">
          <div className='text'>
            {t("Please note that the configurations on customer and line level will precede the community configurations.")}
          </div>
          <div className='sign'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 9.81818C19 14.6711 14.9879 18.6364 10 18.6364C5.01206 18.6364 1 14.6711 1 9.81818C1 4.96525 5.01206 1 10 1C14.9879 1 19 4.96525 19 9.81818Z" stroke="#A44200" strokeWidth="2"/>
            <path d="M10 5.89062V10.7997" stroke="#A44200" strokeWidth="2" strokeLinecap="round"/>
            <ellipse cx="10" cy="13.7474" rx="1" ry="0.981818" fill="#A44200"/>
          </svg>
          </div>
        </div>
        <div className='blocks-list-wrapper'>
          <div className='controls-wrapper'>
            <div className='controls'>
              <div className='text'>
                <strong>{t("Block List Management")}</strong>
                <span>{t("for")}:</span>
              </div>
              <div className='switch-entity'>
                {(
                  props.line.id !== null ?
                  <div className='text'>{props.line.use_name} | {props.line.number}</div> :
                  <div className='text'>{props.customer.name} | {props.customer.phone}</div>
                )}
              </div>
            </div>
            <div className='search'>
              <div className='back'
                onClick={(e) => {props.setCustomerView(CUSTOMER_VIEW_CUSTOMER)}}
              >
                <div className='text'>{t("Back to the customer information")}</div>
                <img src='design-images/arrow-left.svg' className='arrow-left' />
              </div>
              <div className='search-box'>
                <input 
                  type="text" 
                  placeholder={t("Search number in the list")} 
                  onChange={(e)=>{props.changeSearchFilters(e, "number")}}
                  />
                <img src='design-images/search-grey.svg' className='search-icon' />
              </div>
            </div>
          </div>
          <div className='grid-wrapper'>
            <div  className='sidebar'></div>
            <DesktopDataGrid 
              rowCount={props.rowCount}
              rows={props.rows}
              columns={columns}
              page={props.page}
              pageSize={props.pageSize}
              onPageChange={(newPage) => {
                props.setPage(newPage);
              }}
              onPageSizeChange={(newPageSize) => {
                props.setPageSize(newPageSize);
              }}
              expandedRowIds={props.expandedRowIds}
              setExpandedRowIds={props.setExpandedRowIds}
              />
              <div className='controls'>
                <div className='button-add' 
                  onClick={
                    () => {
                      props.handleAddRow();
                    }}
                  >+ {t("Add a row")}
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
