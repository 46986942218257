import React, { useState, useEffect } from 'react';
import { CALL_TYPE_IN, CALL_TYPE_OUT, STATUS_BLOCKED, STATUS_OPEN, CALL_TYPE_ALL} from '../../../Constants';
import Switch from '../../Switch';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import MobileDataGrid from '../../MobileDataGrid';
import Select from '../../Select';
import {Dialog} from '@mui/material';


export default function Category(props) {
  const { t } = useTranslation();
  const [visibleEditExpansionIds, setVisibleEditExpansionIds] = useState([]);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
        if(
          e.target.closest("body") === null 
        || e.target.closest(".body") !== null 
        || e.target.closest(".header") !== null
        || e.target.closest(".expansions-wrapper") !== null
        || e.target.closest("div[role='presentation']") !== null
        ){
        return;
      }else{
        setVisibleEditExpansionIds([]);
      }
    })
  }, []);

  const columns = [
    { field: 'number', headerName: t('Number')},
    { field: 'active', headerName: t('Is Active'),
      valueGetter: (row) => {
        return (
          row.active === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'status', headerName: t('Status'),
      valueGetter: (params) => {return (params.status === STATUS_BLOCKED ? t("Blocked") : t("Open"))}
    },
    { field: 'call_type', headerName: t('Call Type'),
      valueGetter: (params) => {
        let label = null;
        if(params.call_type === CALL_TYPE_OUT){
          label = t("Outgoing");
        }
        if(params.call_type === CALL_TYPE_IN){
          label = t("Incoming");
        }
        if(params.call_type === CALL_TYPE_ALL){
          label = t("Both");
        }
        return label;
      }
    },
    { field: 'note', headerName: t('Note')}
  ];

  const handleEditClick = (e, cellValues) => {
    if(visibleEditExpansionIds.includes(cellValues.id)){
      let newVisibleEditExpansionIds = [...visibleEditExpansionIds];
      newVisibleEditExpansionIds.splice(newVisibleEditExpansionIds.indexOf(cellValues.id), 1);
      setVisibleEditExpansionIds(newVisibleEditExpansionIds);
    }else{
      setVisibleEditExpansionIds([...visibleEditExpansionIds, cellValues.id]);
    }
    e.stopPropagation();
  }

  const renderRowExpansion = (cellValues) => {
    return (props.selectedCategory.isOwner ?
    <>
    <div className={'block-actions'+(!visibleEditExpansionIds.includes(cellValues.id) ? "" : " hidden")}>
      <div className='item phone'>{cellValues.number}</div>
      {(
        props.selectedCategory.isOwner ?
        <div className='item actions'>
          <div className='edit'
            onClick={(e) => {
              handleEditClick(e, cellValues);
            }}>
            <div className='text'>{t('Edit')}</div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="external" d="M22.9739 11.6784C22.9739 17.7988 17.9488 22.7709 11.7369 22.7709C5.52509 22.7709 0.5 17.7988 0.5 11.6784C0.5 5.55803 5.52509 0.585938 11.7369 0.585938C17.9488 0.585938 22.9739 5.55803 22.9739 11.6784Z" fill="#FFC700" stroke="black"/>
            <path d="M6.32031 15.3306V17.6992H8.71849L15.7915 10.7133L13.3933 8.34464L6.32031 15.3306ZM17.6461 8.88153C17.8955 8.63519 17.8955 8.23726 17.6461 7.99092L16.1496 6.51288C15.9002 6.26654 15.4973 6.26654 15.2479 6.51288L14.0776 7.66878L16.4758 10.0374L17.6461 8.88153Z" fill="black"/>
            </svg>
          </div>
          <div className='delete'
            onClick={(e) => {
              props.handleDeleteRow(cellValues);
            }}
          >
            <div className='text'>{t('Delete')}</div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.9739 11.6706C22.9739 17.791 17.9488 22.7631 11.7369 22.7631C5.52509 22.7631 0.5 17.791 0.5 11.6706C0.5 5.55021 5.52509 0.578125 11.7369 0.578125C17.9488 0.578125 22.9739 5.55021 22.9739 11.6706Z" fill="#FFC700" stroke="black"/>
            <path d="M16 8L8 16M16 16L8 8" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div> : ''
      )}
    </div>
    <div 
      className={'edit-form'+(visibleEditExpansionIds.includes(cellValues.id) ? "" : " hidden")} 
      data-block-id={cellValues.id}
      >
      <div className='line'
      key={'edit-expansion-line-'+cellValues.id}
      >
        <div className='input-wrapper'>
          <input type="text" 
            className='underline'
            name="number"
            value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].number : cellValues.number}
            disabled="disabled"
            />
        </div>
        <div className='extra-items'>
          <div className='extra-item-wrapper'>
            <Switch 
              type="boolean"
              name="active"
              value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].active : cellValues.active}
              mainValue={1}
              handleClick={(newValue) => {
                props.handleChangeEditValue(newValue, "active", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
              }}
              />
          </div>
          <div className='extra-item-wrapper'>
            <Switch 
              type="text"
              name="status"
              values={{
                [STATUS_BLOCKED]: t("Blocked"),
                [STATUS_OPEN]: t("Open") 
              }}
              value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].status : cellValues.status}
              mainValue={STATUS_OPEN}
              handleClick={(newValue) => {
                props.handleChangeEditValue(newValue, "status", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
              }}
              />
          </div>
          <div className='extra-item-wrapper'>
            <Select values={{
              [CALL_TYPE_IN]: t("Incoming"),
              [CALL_TYPE_OUT]: t("Outgoing"),
              [CALL_TYPE_ALL]: t("Both")
            }} 
              value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].call_type : cellValues.call_type}
              handleClick={(newValue) => {
                props.handleChangeEditValue(newValue, "call_type", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
              }}
            />
          </div>
        </div>
      </div>
      <div className='note-wrapper'>
        <div className='text'>{t("Note")}:</div>
        <input type="text" 
              className='underline'
              name="note"
              data-id={cellValues.id}
              value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].note : cellValues.note}
              onChange={(e) => {
                props.handleChangeEditValue(e.target.value, "note", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
              }}
              />
        <img src='design-images/save.svg'
          onClick={(e) => {
            props.handleEditLineSubmit(cellValues.id);
          }}
        />
      </div>
    </div>
    </> : ''
    )
  }

  return (  
    <>
      <div className='category-information-mobile'>
        <div className='title-wrapper'>
          <div className='title'>
            <div className='back' onClick={() => {props.viewCategoriesList();}}>
              <img src='design-images/arrow-around.svg' className='arrow-around' />
              <div className='text'>{t("Back to block categories")}</div>
            </div>
            <div className='text current'>{props.selectedCategory.name}</div>
          </div>
        </div>
        <div className='title-wrapper secondary'>
            <div className='text description'>
              <div className='label'>{t("Description")}:</div>
              <div className='value'>{props.selectedCategory.note}</div>
            </div>
            <div className='text details'>
              <div className='item'>{(props.selectedCategory.isPublic === 1 ? t('Public') : t('Not public'))}</div>
              {(
              props.selectedCategory.valid === 1 ? 
              <>
                <div className='text separator'>|</div>
                <div className='text'>{t("Verified")}</div>
                <img src='design-images/yes-mark.svg' />
              </> : 
              ''
              )}
            </div>
          </div>
          <div className='grid-wrapper'>
            <MobileDataGrid 
              rowCount={props.rowCount}
              rows={props.rows}
              columns={columns}
              page={props.page}
              pageSize={props.pageSize}
              onPageChange={(newPage) => {
                props.setPage(newPage);
              }}
              onPageSizeChange={(newPageSize) => {
                props.setPageSize(newPageSize);
              }}
              renderRowExpansion={renderRowExpansion}
              expandedRowIds={props.expandedRowIds}
              setExpandedRowIds={props.setExpandedRowIds}
              mainColumn="number"
              swapPagesColumns={[
                ["active","status", "call_type"],
                ["note"]
              ]}
              />
              <div className='controls'>
                  <div className='button-add' 
                    onClick={
                      () => {
                        props.handleAddClick();
                      }}
                    >+ {t("Add a row")}</div>
                  <div className='search-wrapper'>
                      <input 
                        type="text" 
                        className="search" 
                        id="search-value" 
                        placeholder={t("Search")}
                        onChange={(e) => {
                          props.changeSearchFilters(e.target.value, "number");
                        }}
                         />
                      <img src="design-images/search.svg" 
                        className='button'
                        onClick={props.handleSearchSubmit}
                         />
                  </div>
              </div>
          </div>
      </div>
      <Dialog open={props.isAddFormVisible} 
      onClose={() => {props.setIsAddFormVisible(false);}}
      className="new block popup"
        >
        <div className='title'>
          <div className='text-wrapper'>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.19531 1.80469V13.8047M13.1953 7.80469H1.19531" stroke="#FFC700" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div className='text'>{t("Add new record")}</div>
          </div>
        </div>
        <div className='content'>
          <input type="text"
            className='underline'
            placeholder={t("Number")}
            value={props.addValue.number}
            onChange={(e) => {
              if(props.isValidValue(e.target.value, "number")){
                props.setAddValue({...props.addValue, "number": e.target.value})
              }
            }}
            />
          <Switch 
              type="text"
              name="active"
              values={{
                "1": t("Active"),
                "0": t("Inactive") 
              }}
              value={props.addValue.active}
              mainValue={"1"}
              handleClick={(newValue) => {
                props.setAddValue({...props.addValue, "active": newValue})
              }}
            />
            <Switch 
              type="text"
              name="status"
              values={{
                [STATUS_BLOCKED]: t("Blocked"),
                [STATUS_OPEN]: t("Open") 
              }}
              value={props.addValue.status}
              mainValue={STATUS_OPEN}
              handleClick={(newValue) => {
                props.setAddValue({...props.addValue, "status": newValue})
              }}
            />
          <div className='switch-wrapper'>
            <div className='text'>{t("Call Type")}:</div>
            <Select values={{
                [CALL_TYPE_IN]: t("Incoming"),
                [CALL_TYPE_OUT]: t("Outgoing"),
                [CALL_TYPE_ALL]: t("Both")
              }} 
              value={props.addValue.call_type}
              handleClick={(newValue) => {
                props.setAddValue({...props.addValue, "call_type": newValue})
              }}
              />
          </div>
          <input type="text"
            className='underline'
            placeholder={t("Note")}
            value={props.addValue.note}
            onChange={(e) => {
              props.setAddValue({...props.addValue, "note": e.target.value})
            }}
            />
          <div className='controls'>
            <img src='design-images/save-black.svg'
               onClick={(e) => {
                props.handleAddLineSubmit();
              }}
             />
          </div>
        </div>
    </Dialog>
    </>
  );
}
