import React, { useState } from 'react';
import Device from './Device';
import { useTranslation } from 'react-i18next';

export default function LineInfo(props) {
  const { t } = useTranslation();
  const [isDeviceInfoVisible, setIsDeviceInfoVisible] = useState(false);

  return (<>
    <div className='main'>
        <div className='item'>{props.line["use_name"]}</div>
        <div className='item'>{props.line["number"]}</div>
    </div>
    <div className='general-info'>
        <div className='item'>
        <div className='field'>{t("Joined at")}</div>
        <div className='value'>{props.line["create_date"]}</div>
        </div>
        <div className='item'>
        <div className='field'>{t("Last Call")}</div>
        <div className='value'>{props.line["last_call"]}</div>
        </div>
    </div>
    <div className={'device-info-button'+(isDeviceInfoVisible ? '' : ' collapsed')}
        onClick={(e)=>{
            setIsDeviceInfoVisible(!isDeviceInfoVisible);
            e.stopPropagation();
        }}
    >{
        isDeviceInfoVisible ?
        t("Hide Device Details"): 
        t("Show Device Details")
    }</div>
    {(
        isDeviceInfoVisible ?
        <Device customer={props.customer} line={props.line} /> : ''
    )}
    </>);
}
