import {DEFAULT_ERROR_TITLE, ERROR_SESSION_EXPIRED, ERROR_BAD_SESSION, API_EXCEPTION, API_OK, API_URL, DEFAULT_ERROR_TEXT} from './Constants'
import cookie from "js-cookie";
import axios from "axios";

export function formatErrorMessage(errorMessage, t){
    const translatedErrorMessage = t(errorMessage);
    if (translatedErrorMessage !== errorMessage){
        errorMessage = errorMessage + " - " + translatedErrorMessage;
    }
    return errorMessage;
}

export function handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate, showOnPage = "all") {
    const sessionErrors = [
        ERROR_SESSION_EXPIRED,
        ERROR_BAD_SESSION
    ];

    if(sessionErrors.includes(errorMessage)
        && (logout !== null)){
        if((errorMessage !== ERROR_SESSION_EXPIRED) && (typeof errorMessage !== 'undefined')){
            cookie.set('logout_error', errorMessage);
        }
        
        dispatch(logout());
        navigate("/", { replace: true });
    }else{
        if(typeof errorMessage !== 'undefined'){
            errorMessage = formatErrorMessage(errorMessage, t);
            dispatch(showErrorDialog({title : t(DEFAULT_ERROR_TITLE), text: errorMessage, page: showOnPage}));
        }
    }
}

export async function getCommunityGeneralInfo(loginToken, t, dispatch, showErrorDialog, logout, navigate){
    let  headers = {
        token: loginToken
      }
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    let generalInfo = {};
    await axios.get(API_URL + '/getStat', {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data['responseStatus'] === API_OK)){
            generalInfo = 
            {
              activeLines:data.active_lines, 
              activeCustomers:data.active_customers, 
              blockListMode: {incoming: data.blockListMode.incoming, outcoming: data.blockListMode.outcoming},
              name: data.name,
              note: data.note,
              smsWatchSystemAllow: data.smsWatchSystemAllow,
              showRedInactiveLines: data.showRedInactiveLines,
            };
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      });
    return generalInfo;
}