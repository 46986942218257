import * as React from 'react';
import {NavLink} from 'react-router-dom'
import {APP_NAME, APP_NAME_SIMKEHILOT} from '../Constants'
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

export default function ButtonAppBar() {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const user = useSelector((state) => state.user.value);
  

  const handleMobileMenuClose = () => {
    document.getElementById("mobile-navigation-controls").classList.add("hidden");
  };

  const handleMobileMenuOpen = () => {
    document.getElementById("mobile-navigation-controls").classList.remove("hidden");
  };

  
  return (
      <>
        <div className='navigation-bar-desktop'>
          <div className='controls-wrapper'>
            <div className='controls-inner-wrapper'>
              <div className='controls'>
                <NavLink
                    to="/dashboard" style={{ textDecoration: 'none' }}>
                  <div className='logo-wrapper'>
                    <img src={'design-images/'+APP_NAME+'/logo-for-menu.png'} className='logo' />
                    {(APP_NAME === APP_NAME_SIMKEHILOT ? <div className='text'>{t('Mahadrin speech')}</div> : '')}
                  </div>
                </NavLink>
                <div className='links-wrapper'>
                  <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                  to="/dashboard" style={{ textDecoration: 'none' }}>
                    {t('Dashboard')}
                  </NavLink>
                  <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                  to="/blocks-management" style={{ textDecoration: 'none' }}>
                    {t('Blocks Management')}
                  </NavLink>
                  <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                    to="/customers-management" style={{ textDecoration: 'none' }}>
                    {t('Customers Management')}
                  </NavLink>
                  <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                    to="/clock-system" style={{ textDecoration: 'none' }}>
                    {t('Clock System')}
                  </NavLink>
                  <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                    to="/anti-spam" style={{ textDecoration: 'none' }}>
                    {t('Anti spam service')}
                  </NavLink>
                </div>
              </div>
              <div className='welcome-bar'>
                <div className='welcome'>{t("Welcome,")}</div>
                <div className='community'>
                  <span>{t("Community of")}</span>
                  <span className='community-name'>{user.community_name}</span>
                </div>
              </div>
            </div>
            <img src={'design-images/'+APP_NAME+'/navbar-decoration.png'} className='decoration' />
            <div className='logout-wrapper'>
              <NavLink to="/logout" className='logout' style={{ textDecoration: 'none' }}>
                  {t('Logout')}
              </NavLink>
            </div>
          </div>
        </div>
        <div className='navigation-bar-mobile'>
          <div className='controls'>
              <img className="menu-icon" src='design-images/menu-icon.svg' onClick={handleMobileMenuOpen} />
              <NavLink className="logo-link"
                  to="/dashboard" style={{ textDecoration: 'none' }}>
                  <img src={'design-images/'+APP_NAME+'/logo-for-menu.png'} className='logo' />
              </NavLink>
            </div>
            <div className='welcome-bar'>
              <div className='welcome'>{t("Welcome,")}</div>
              <div className='community'>
                <span>{t("Community of")}</span>
                <span className='community-name'>{user.community_name}</span>
              </div>
            </div>
        </div>
        <div className='mobile-navigation-controls hidden' id="mobile-navigation-controls">
          <img src="design-images/close-black.svg" className='close' onClick={handleMobileMenuClose} />
            <div className='links-wrapper'>
                <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                to="/dashboard" 
                onClick={handleMobileMenuClose}
                style={{ textDecoration: 'none' }}>
                  {t('Dashboard')}
                </NavLink>
                <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                to="/blocks-management" 
                onClick={handleMobileMenuClose}
                style={{ textDecoration: 'none' }}>
                  {t('Blocks Management')}
                </NavLink>
                <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                  to="/customers-management" 
                  onClick={handleMobileMenuClose}
                  style={{ textDecoration: 'none' }}>
                  {t('Customers Management')}
                </NavLink>
                <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                  to="/clock-system" 
                  onClick={handleMobileMenuClose}
                  style={{ textDecoration: 'none' }}>
                  {t('Clock System')}
                </NavLink>
                <NavLink className={(navData) => (navData.isActive ? "active" : "")}
                  to="/anti-spam" 
                  onClick={handleMobileMenuClose}
                  style={{ textDecoration: 'none' }}>
                  {t('Anti spam service')}
                </NavLink>
              </div>
              <NavLink to="/logout" className='logout' 
              onClick={handleMobileMenuClose}
              style={{ textDecoration: 'none' }}>
                {t('Logout')}
              </NavLink>
        </div>
      </>
  );
}