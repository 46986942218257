import React, { useState } from 'react';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import MobileDataGrid from '../../MobileDataGrid';
import {MenuItem} from '@mui/material';
import MuiSelect from '@mui/material/Select';


export default function CategoriesGridWrapperMobile(props) {
  const { t } = useTranslation();
  const [currentSearchField, setCurrentSearchField] = useState("name");

  const columns = [
    { field: 'name', headerName: t('Name')},
    { field: 'public', type: 'boolean', headerName: t('Is Public'),
      valueGetter: (row) => {
        return (
          row.public === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'create_date', headerName: t('Created at')},
    { field: 'isOwner', type: 'boolean', headerName: t('Is Owner'),
      valueGetter: (row) => {
        return (
          row.isOwner === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'valid', headerName: t('Verified category'),  
      valueGetter: (row) => {
        return (
          row.valid === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'myJoin', type: 'boolean', headerName: t('Is Joined'),
      valueGetter: (row) => {
        return (
          row.myJoin === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    }
  ];

  const renderRowExpansion = (cellValues) => {
    return <>
      <div className='main'>
        <div className='title'>{cellValues["name"]}</div>
        <div className='controls'>
          <div className='item view'
            onClick={(e) => props.handleViewRow(cellValues)}
          >
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className='external' d="M16.755 8.5C16.755 12.9113 13.1232 16.5 8.6275 16.5C4.13176 16.5 0.5 12.9113 0.5 8.5C0.5 4.08871 4.13176 0.5 8.6275 0.5C13.1232 0.5 16.755 4.08871 16.755 8.5Z" fill="#FFC700" stroke="white"/>
            <path d="M2.92188 8.75203C6.1639 4.0823 11.3511 4.08301 14.5932 8.75203C11.3511 13.4197 6.1639 13.4197 2.92188 8.75203Z" fill="white"/>
            <circle cx="8.74832" cy="8.74832" r="1.58426" stroke="#FFC700" strokeWidth="1.5"/>
            </svg>
            <div className='text'>{t("Show")}</div>
          </div>
          <div className='item separator'></div>
          <div className='item delete'
            onClick={() => props.handleDeleteRow(cellValues)}
          >
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className='external' d="M16.755 8.5C16.755 12.9113 13.1232 16.5 8.6275 16.5C4.13176 16.5 0.5 12.9113 0.5 8.5C0.5 4.08871 4.13176 0.5 8.6275 0.5C13.1232 0.5 16.755 4.08871 16.755 8.5Z" fill="#FFC700" stroke="white"/>
            <path d="M11.6641 5.84375L5.82842 11.6794M11.6641 11.6794L5.82842 5.84375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div className='text'>{t("Delete")}</div>
          </div>
        </div>
      </div>
      <div className='info'>
        <div className='line'>
          <div className='label'>{t("Category created at")}:</div>
          <div className='value'>{cellValues["create_date"]}</div>
        </div>
        <div className='line'>
          <div className='label'>{t("Active numbers")}:</div>
          <div className='value'>{cellValues["active_setting"]}</div>
        </div>
      </div>
    </>;
  }

  return (  
    <div className="categories-wrapper">
      <div className='search-wrapper'>
          <MuiSelect
            size="small"
            value={currentSearchField}
            onChange={(e) => {
              setCurrentSearchField(e.target.value);
            }}
            className="field"
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          >
            <MenuItem value="name">{t("Category name")}</MenuItem>
            <MenuItem value="onlyOwner">{t("Is Owner")}</MenuItem>
            <MenuItem value="onlyMy">{t("Is Joined")}</MenuItem>
            <MenuItem value="onlyValid">{t("Only Verified Categories")}</MenuItem>
          </MuiSelect>
          {(
            currentSearchField === "name" ? 
            <input 
              type="text" 
              className="search"
              placeholder={t("Search")}
              value={props.searchInput[currentSearchField]} 
              onChange={(e) => props.changeSearchFilters(e.target.value, currentSearchField)} /> :
              <MuiSelect
                size="small"
                className="search"
                value={props.searchInput[currentSearchField]} 
                onChange={(e) => props.changeSearchFilters(e.target.value, currentSearchField)}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              >
                <MenuItem value={false}>{t("Show All")}</MenuItem>
                <MenuItem value={true}>{t("Yes")}</MenuItem>
              </MuiSelect>
          )}
          <img src="design-images/search.svg" className='button' onClick={(e) => props.handleSearchSubmit()} />
      </div>
      <div className='title-wrapper'>
        <div className='title'>{t('Block categories')}</div>
      </div>
      <div className='grid-wrapper'>
        <MobileDataGrid 
          rowCount={props.rowCount}
          rows={props.rows}
          columns={columns}
          loading={props.loading}
          mainColumn="name"
          page={props.page}
          pageSize={props.pageSize}
          onPageChange={(newPage) => {
            props.setPage(newPage);
          }}
          swapPagesColumns={[
            ["public","myJoin"],
            ["isOwner","valid"]
          ]}
          renderRowExpansion={renderRowExpansion}
          expandedRowIds={props.expandedRowIds}
          setExpandedRowIds={props.setExpandedRowIds}
          />
          <div className='controls'>
            <div className='button-add' 
              onClick={
                () => {
                  props.handleAddRow();
                }}
              >+ {t("Add a row")}
            </div>
          </div>
      </div>
    </div>
  );
}
