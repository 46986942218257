import { style } from '@mui/system';
import React from 'react';
import '../styles.css';


export default function Switch(props) {

  const handleClick = (e) => {
    let newValue = props.value;
    if(props.type === "boolean"){
      newValue = (props.value === 0 ? 1 : 0);
    }else{
      for(let itemKey in props.values){
        if(itemKey !== props.value){
          newValue = itemKey;
        }
      }
    }
    
    props.handleClick(newValue, e);
  }

  return (  
    props.value === props.mainValue ?
    <div className={
      'switch-type main'
      +(props.hasOwnProperty("className") ? " "+props.className : "")
      + " " + props.type
    }
    onClick={(e) => {handleClick(e)}}
    style={props.style}
    >
      <div className='text'>
      {(
        props.type === "boolean" ? 
        (props.value === 1 ?
          <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9.5L7 15.5L21.5 1" stroke="black" strokeWidth="2" strokeLinecap="round"/>
          </svg>
          :
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2L2 12M12 12L2 2" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        ) :
        props.values[props.value]
      )}
      </div>
      <svg className="circle" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="8.5" r="8.5" fill="white"/>
      </svg>
    </div> :
    <div className={
      'switch-type'
      +(props.hasOwnProperty("className") ? " "+props.className : "")
      + " " + props.type
    }
      onClick={handleClick}
      style={props.style}
      >
      <svg className="circle" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="8.5" r="8.5" fill="white"/>
      </svg>
      <div className='text'>
      {(
        props.type === "boolean" ? 
        (props.value === 1 ?
          <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9.5L7 15.5L21.5 1" stroke="black" strokeWidth="2" strokeLinecap="round"/>
          </svg>
          :
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2L2 12M12 12L2 2" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        ) :
        props.values[props.value]
      )}
      </div>
    </div>
  );
}
