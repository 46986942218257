import React, { useState } from 'react';
import { WATCH_STATUS_ON, WATCH_STATUS_OPEN, WATCH_TYPE_CALL, WATCH_TYPE_SMS } from '../../Constants';
import { WATCH_STATUS_BLOCKED } from '../../Constants';
import { useTranslation } from 'react-i18next';
import '../../styles.css';
import {Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function WatchSystem(props) {
  const { t } = useTranslation();
  const [showExcludedNumbersSection, setShowExcludedNumbersSection] = useState(false);

  return (<>
      <div className='watch-content-wrapper'
        onClick={() => setShowExcludedNumbersSection(false)}
      >
        {(
          props.showErrorMessage ? 
          <div className='error-message'>
            <div>{props.errorMessage}</div>
            <IconButton onClick={() => props.setShowErrorMessage(false)}>
              <CloseIcon />
            </IconButton>
          </div> : ''
        )}
        {(
          props.showWarningMessage ? 
          <div className='warning-message'>
            <div className='warning-message-content'>
              <div className='warning-message-title'>{props.warningMessageTitle}</div>
              <ul className='warning-message-errors'>
                {props.warningMessageErrorLines()}
              </ul>
            </div>
            <IconButton onClick={() => props.setShowWarningMessage(false)}>
              <CloseIcon />
            </IconButton>
          </div> : ''
        )}
        <div className='watch-content'>
          {(
            props.displayWatchTypeSwitch ? 
            <div className='status-wrapper'>
                <div className='title'>{t('Watch type:')}</div>
                <div className='values-wrapper'>
                    <div className={'value'+(props.watchType=== WATCH_TYPE_CALL ? ' selected' : '')}
                    data-value={WATCH_TYPE_CALL}
                    onClick={() => 
                        {
                        if(props.watchType !== WATCH_TYPE_CALL){
                            props.setWatchType(WATCH_TYPE_CALL);
                        }
                        }}
                    >{t("Calls watch")}</div>
                    <div className={'value'+(props.watchType === WATCH_TYPE_SMS ? ' selected' : '')}
                    data-value={WATCH_TYPE_SMS}
                    onClick={() => 
                        {
                        if(props.watchType !== WATCH_TYPE_SMS){
                            props.setWatchType(WATCH_TYPE_SMS);
                        }
                        }}
                    >{t("SMS watch")}</div>
                </div>
              </div> : ''
          )}
          <div className='status-wrapper'>
            <div className='title'>{t('Watch status:')}</div>
            {(
              props.showSuccessMessage ?
              <div className='success-message-wrapper'>
                <div className='saved-message'>
                  <div className='text'>{t('The watch details has been saved successfully.')}</div>
                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.68966L3.92683 9L11.5 1.5" stroke="#FFC700" strokeWidth="1.7" strokeLinecap="round"/>
                    </svg>
                </div> 
              </div> :
              <div className='values-wrapper'>
                <div className={'value'+(props.watchStatus === WATCH_STATUS_OPEN ? ' selected' : '')}
                data-value={WATCH_STATUS_OPEN}
                onClick={() => 
                  {
                    if(props.watchStatus !== WATCH_STATUS_OPEN){
                      props.setWatchStatus(WATCH_STATUS_OPEN);
                      props.setIsWatchDataModified(true);
                    }
                  }}
                  >{t("Watch is open")}</div>
                <div className={'value'+(props.watchStatus === WATCH_STATUS_BLOCKED ? ' selected' : '')}
                  data-value={WATCH_STATUS_BLOCKED}
                  onClick={() => 
                    {
                      if(props.watchStatus !== WATCH_STATUS_BLOCKED){
                          props.setWatchStatus(WATCH_STATUS_BLOCKED);
                          props.setIsWatchDataModified(true);
                      }
                    }}
                >{t("Watch is closed")}</div>
                <div className={'value'+(props.watchStatus === WATCH_STATUS_ON ? ' selected' : '')}
                  data-value={WATCH_STATUS_ON}
                  onClick={() => 
                    {
                      if(props.watchStatus !== WATCH_STATUS_ON){
                          props.setWatchStatus(WATCH_STATUS_ON);
                          props.setIsWatchDataModified(true);
                      }
                      
                    }}
                >{t("Watch is on")}</div>
              </div>
            )}
          </div>
          <div className='profile-wrapper'>
            <div className='profile-content-wrapper'>
              <div className='profile-content'>
                <div className='days-title'>
                  <div className='days'>
                    {props.getDayLabels(true)}
                  </div>
                </div>
                <div className='day-profile'>
                  <div className='column right'>{props.getDayLines(props.selectedDayIndex, 0, 11, false)}</div>
                  <div className='column'>{props.getDayLines(props.selectedDayIndex, 12, 23, false)}</div>
                </div>
              </div>
              {(
                props.watchStatus !== WATCH_STATUS_ON ?
                <div className='overlay'></div> : ''
              )}
            </div>
          </div>
        </div>
      </div>
      {(
        showExcludedNumbersSection ? 
        <div className="excluded-numbers-wrapper"
          onClick={(e) => e.stopPropagation()}
        >
          <div className='top-wrapper' onClick={() => setShowExcludedNumbersSection(false)}>
            <div className='horizontal-line'></div>
          </div>
          <div className="title">{t("Excluded numbers from the watch:")}</div>
          <div className='chips'>
              {props.getExcludedNumbersChips()}
            </div>
          <div className='enter-numbers-wrapper'>
            {(
              !props.addNumberInputVisible ?
              <div className='add-button'
                onClick={() => {
                  props.setAddNumberInputVisible(true);
                }}
              >+ {t("Add number")}</div> :
              <div className='add-input-wrapper'>
                <input type="text"
                id="excluded_numbers"
                  className='underline' />
                  <img 
                    className='white-plus-in-yellow'
                    onClick={() => props.handleAddExcludedNumber()}
                    src='design-images/plus-white.svg' />
              </div>
            )}
          </div>
        </div> : 
        <div className='excluded-numbers-slider'
          onClick={() => setShowExcludedNumbersSection(true)}
        >
          <div className='horizontal-line'></div>
          <div className='text'>{t("Excluded numbers from the watch")}</div>
        </div>
      )}
    <Dialog 
    className='apply-watch-settings popup'
    open={props.applyWatchSettingsDialogIsOpen}
    fullWidth
      >
        <div className='content'>
          <div className='text'>{t('Apply changes on the following days -')}</div>
          <div className='days-wrapper'>
            {props.getApplyDays()}
          </div>
          <div className='controls'>
            <div className='proceed'
              onClick={props.applyProfileChangesOnSelectedDays}
              >
                {t("Yes")}
            </div>
            <div className='cancel'
              onClick={() => {
                props.setApplyWatchSettingsDialogIsOpen(false);
                props.setSelectedDayIndex(props.nextSelectedDayIndex);
              }}
              >
                {t("No")}
            </div>
          </div>
        </div>
    </Dialog>
  </>
  );
}
