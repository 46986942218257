import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_URL, API_OK, ACTION_ADD, ACTION_EDIT, CALL_TYPE_IN, CALL_TYPE_OUT, STATUS_BLOCKED, STATUS_OPEN, API_EXCEPTION, DEFAULT_ERROR_TEXT, ALL, CALL_TYPE_ALL, CUSTOMER_VIEW_CUSTOMER} from '../../Constants';
import { FormControlLabel, Backdrop, CircularProgress} from '@mui/material';
import {Button, Select, MenuItem, FormControl, InputLabel, Dialog} from '@mui/material';
import Switch from '@mui/material/Switch';
import '../../styles.css';
import cookie from "js-cookie";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import {handleApiErrorMessage} from "../../Helper";
import BlockCategoryDesktop from "../Customer/Desktop/BlockCategory";
import BlockCategoryMobile from "../Customer/Mobile/BlockCategory";


export default function BlockCategory(props) {
  const { t } = useTranslation();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState("");

  const initalSelectedCategoryValue = {
    id: null,
    name: null,
    note: null,
    public: 1,
    privacy_level: 0,
    isOwner: 1,
    myJoin: 0,
    valid: 0,
    active_setting: 0
  };
  const [selectedCategory, setSelectedCategory] = useState(initalSelectedCategoryValue);
  const [selectedCustomer, setSelectedCustomer] = useState(props.customer);
  const [linesInCustomer, setLinesInCustomer] = useState([]);

  const initialLine = {
    id: null,
    number: "",
    use_name: ""
  };
  const [isLinesListVisible, setIsLinesListVisible] = useState(false);
  const [lineToDelete, setLineToDelete] = useState(initialLine);

  const initialLineFormInput = {
    title: t("Add new number"),
    id : null,
    number : "",
    active: 1,
    note: "",
    action: ACTION_ADD,
    call_type: CALL_TYPE_OUT,
    status: STATUS_BLOCKED
  };
  const [lineFormInput, setLineFormInput] = useState(initialLineFormInput);

  const initialSearchInput = {
    number : "",
    active: ALL,
    call_type: ALL,
    status: ALL
  };
  const [searchInput, setSearchInput] = useState(initialSearchInput);

  const [dialogLoaderVisible, setDialogLoaderVisible] = useState(false);
  const [selectionIds, setSelectionIds] = useState(false);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const defaultSuccessMessage = t("The operation was successfull");

  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if(loading){
      return () => {};
    }

    if(props.hasOwnProperty("category")
      && props.category.hasOwnProperty("id")
      && Number.isInteger(props.category.id)){
        const selectedCategoryValueFromProps = {
          id: props.category.id,
          name: props.category.name,
          note: props.category.note,
          public: props.category.public,
          isOwner: props.category.isOwner,
          myJoin: props.category.myJoin,
          valid: props.category.valid,
          privacy_level: props.category.privacy_level
        };
    
        setSelectedCategory(selectedCategoryValueFromProps);
    }
    
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
        if(e.target.closest(".body") !== null 
        || e.target.closest(".header") !== null
        || e.target.closest(".expansions-wrapper") !== null
        ){
        return;
      }else{
        setExpandedRowIds([]);
      }
    })
  }, []);

  useEffect(() => {
    if(loading){
      return () => {};
    }
    if(!Number.isInteger(selectedCategory.id)
      && !Number.isInteger(selectedCustomer.id)
    ){
      return () => {};
    }
    const requestSource = refreshBlockedData(); 
    return () => {
      requestSource.cancel();
    };
  }, [page, pageSize]);

  useEffect(() => {
    if(!Number.isInteger(selectedCategory.id)
      && !Number.isInteger(selectedCustomer.id)
    ){
      return () => {};
    }
    const blockedDataRequestSource = refreshBlockedData(); 
    const linesRequestSource = refreshLinesData();
    
    return () => {
      blockedDataRequestSource.cancel();
      linesRequestSource.cancel();
    };
  }, [selectedCategory.id, selectedCustomer.id, searchInput, props.selectedLine.id]);


  const refreshLinesData = () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);
    setDialogLoaderVisible(true);

    let  headers = {
      token: cookie.get('token')
    }

    const requestSource = axios.CancelToken.source();
    headers.cancelToken = requestSource.token;

    axios.post(API_URL + '/getLinesInCustomers', 
    {
      customer_id: props.customer.id,
      start: 0,
      limit: 10000
    }, 
    {headers}
    ).then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("lines"))){
          setLinesInCustomer(data.lines);
          setLoading(false);
          resetDialogHideLoader();
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
          setDialogLoaderVisible(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
        setDialogLoaderVisible(false);
    });


    return requestSource;
  };


    const refreshBlockedData = (pageToStart = null) => {
      if(pageToStart === null){
        pageToStart = page;
      }
      let errorMessage = t(DEFAULT_ERROR_TEXT);
      setLoading(true);

      let  headers = {
        token: cookie.get('token')
      }

      let apiUrl = API_URL + '/getBlockList';
      if(Number.isInteger(selectedCategory.id)
          && (selectedCategory.id > 0)){
            apiUrl = API_URL + '/categories/'+selectedCategory.id+'/getBlockList';
      }else{
        if(Number.isInteger(selectedCustomer.id)
        && (selectedCustomer.id > 0)){
            apiUrl = API_URL + '/by_customer/'+selectedCustomer.id+'/getBlockList';

            if(Number.isInteger(props.selectedLine.id)
              && (props.selectedLine.id > 0)){
                  apiUrl = API_URL + '/by_line/'+selectedCustomer.id+'/'+props.selectedLine.id+'/getBlockList';
            }
        }
      }

      const requestSource = axios.CancelToken.source();
      headers.cancelToken = requestSource.token;

      axios.post(
        apiUrl, 
        {searchStr: searchInput.number, active: searchInput.active === ALL ? ALL : Number(searchInput.active), status: searchInput.status, call_type: searchInput.call_type, start: pageToStart*pageSize, limit: pageSize}, 
        {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("results") && data.hasOwnProperty("totalBlocks"))){
            setRows(data.results);
            setRowCount(data.totalBlocks);
            setLoading(false);
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
      });


      return requestSource;
    };

  const handleAddRow = () => {
    setLineFormInput(lineFormInput);
    setDialogIsOpen(true);
  };

  const deleteRows = async (rowIds) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    setDialogLoaderVisible(true);

    let  headers = {
      token: cookie.get('token')
    }

    const data = {
      blockIds: rowIds
    };

    let apiUrl = API_URL + '/deleteBlocks';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/deleteBlocks';
    }else{
      if(Number.isInteger(selectedCustomer.id)
      && (selectedCustomer.id > 0)){
          apiUrl = API_URL + '/by_customer/'+selectedCustomer.id+'/deleteBlocks';

          if(Number.isInteger(props.selectedLine.id)
            && (props.selectedLine.id > 0)){
                apiUrl = API_URL + '/by_line/'+selectedCustomer.id+'/'+props.selectedLine.id+'/deleteBlocks';
          }
      }
    }

    axios.post(apiUrl, data, {headers})
    .then(
        (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          refreshBlockedData();
        }else{
          if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
        resetDialogHideLoader();
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);

        resetDialogHideLoader();
    });
  };

  const handleDeleteRow = async (row) => {
    deleteRows([row.id]);
  };
  
  const handleDeleteRows = async () => {
    if(!selectionIds){
      alert(t('No lines selected'));
      return;
    }else{
      let nonRemovedRows = rows.filter((row) => (!selectionIds.includes(row.id)));
      if(nonRemovedRows.length === rows.length){
        alert(t('No lines selected'));
        return;
      }
    }
  
    deleteRows(selectionIds);
  };

  const getLineDataIndexById = (lineDataId) => {
    let lineDataIndex = -1;
    for (let index = 0; index < rows.length; index++) {
      const lineData = rows[index];
      if(lineData.id === lineDataId){
        lineDataIndex = index;
        break;
      }
    }
    return lineDataIndex;
  };

  const resetDialogHideLoader = () => {
    setDialogLoaderVisible(false);
    setDialogIsOpen(false);
    setLineFormInput(initialLineFormInput);
  };

  const displaySuccessMessage = (successMessage = null) => {
    if(successMessage != null){
      setSuccessMessage(successMessage);
    }else{
      setSuccessMessage(defaultSuccessMessage);
    }
    
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 2000);
  };

  const addLinesData = (linesDataToAdd) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/addBlockList';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/addBlockList';
    }else{
      if(Number.isInteger(selectedCustomer.id)
      && (selectedCustomer.id > 0)){
          apiUrl = API_URL + '/by_customer/'+selectedCustomer.id+'/addBlockList';

          if(Number.isInteger(props.selectedLine.id)
            && (props.selectedLine.id > 0)){
                apiUrl = API_URL + '/by_line/'+selectedCustomer.id+'/'+props.selectedLine.id+'/addBlockList';
          }
      }
    }

    axios.post(apiUrl, linesDataToAdd, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          await refreshBlockedData();
          resetDialogHideLoader();
          displaySuccessMessage();
          setShowSuccessMessage(true);
        }else{
          if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setDialogLoaderVisible(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setDialogLoaderVisible(false);
    });
  };


  const createCategory = (categoryData) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/categories/create';

    axios.post(apiUrl, {new_obj: categoryData}, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK && responseData.data.hasOwnProperty('newCategorieId')){
          let selectedCategoryData = {
            id: responseData.data.newCategorieId,
            name: selectedCategory.name,
            note: selectedCategory.note,
            public: selectedCategory.public,
            isOwner: selectedCategory.isOwner,
            myJoin: selectedCategory.myJoin
          };
          setSelectedCategory(selectedCategoryData);
          resetDialogHideLoader();
        }else{
          if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setDialogLoaderVisible(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setDialogLoaderVisible(false);
    });
  };

  const handleAddBlockSubmit = () => {
    setDialogLoaderVisible(true);
      
    const data = {
      numbers: [
        {
          number: lineFormInput.number,
          active: lineFormInput.active,
          note: lineFormInput.note,
          call_type: lineFormInput.call_type,
          status: lineFormInput.status
        }
      ]
    };

    addLinesData(data);
  }

  const handleEditBlockSubmit = (blockData) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    setDialogLoaderVisible(true);

    let  headers = {
      token: cookie.get('token')
    }
    
    const data = {
      blockId: blockData.id,
      newData: {
        note: blockData.note,
        active: blockData.active,
        call_type: blockData.call_type,
        status: blockData.status
      }
    };

    let apiUrl = API_URL + '/editBlock';
    if(Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)){
          apiUrl = API_URL + '/categories/'+selectedCategory.id+'/editBlock';
    }else{
      if(Number.isInteger(selectedCustomer.id)
      && (selectedCustomer.id > 0)){
          apiUrl = API_URL + '/by_customer/'+selectedCustomer.id+'/editBlock';

          if(Number.isInteger(props.selectedLine.id)
            && (props.selectedLine.id > 0)){
                apiUrl = API_URL + '/by_line/'+selectedCustomer.id+'/'+props.selectedLine.id+'/editBlock';
          }
      }
    }

    axios.post(apiUrl, data, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          await refreshBlockedData();
          displaySuccessMessage();
        }else{
          if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
        resetDialogHideLoader();
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        resetDialogHideLoader();
    });
  }

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogIsOpen(false);
  };

  const changeSearchFilters = (e, fieldName) => {
    let newSearchInput = {
      number : searchInput.number,
      active: searchInput.active,
      call_type: searchInput.call_type,
      status: searchInput.status
    };
    newSearchInput[fieldName] = e.target.value;
    setSearchInput(newSearchInput);
  };

  const changeLineValue = (e, type='', id='') => {
    let value = e.target.value;

    let targetId = (id === "" ? e.target.id : id);

    if(type === "number"){
      if(!/\d+/.test(value)){
          setPhoneErrorText(t("The phone can only contain numbers"));
          return;
      }

      setPhoneErrorText("");
    }
    if(targetId === "active"){
      value = (e.target.checked ? 1 : 0);
    }
    let newFormInput = {
      title : lineFormInput.title,
      id : lineFormInput.id,
      number : lineFormInput.number,
      active: lineFormInput.active,
      note: lineFormInput.note,
      action: lineFormInput.action,
      call_type: lineFormInput.call_type,
      status: lineFormInput.status
    };
    newFormInput[targetId] = value;
    setLineFormInput(newFormInput);
}

const displayDeletePopup = (lineData) => {
  setLineToDelete(lineData);
  setDeleteDialogIsOpen(true);
}

const displayEditPopup = (lineData) => {
  setLineFormInput(lineData);
  setDialogIsOpen(true);
}



const handleSwitchClick = (e) => {
  if(props.selectedLine.id === null && linesInCustomer.length > 0){
    props.setSelectedLine(linesInCustomer[0]);
  }else{
    props.setSelectedLine(initialLine);
  }
};

const handleSelectLine = (e) => {
  for(let index=0; index<linesInCustomer.length; index++){
    if(""+linesInCustomer[index].id === e.target.getAttribute("data-id")){
      props.setSelectedLine(linesInCustomer[index]);
    }
  }
}

const getLinesList = () => {
  let lines = [];

  lines.push(
    <div 
      className='line selected' 
      key={props.selectedLine.id}
      data-id={props.selectedLine.id}
      onClick={handleSelectLine}
      >
         <div className='text'>{props.selectedLine.use_name + " | " + props.selectedLine.number}</div>
        <div className='arrow arrow-up'></div>
    </div>
  );

  for (let index = 0; index < linesInCustomer.length; index++) {
    if(linesInCustomer[index].id === props.selectedLine.id){
      continue;
    }
    lines.push(
    <div 
      className='line' 
      key={linesInCustomer[index].id}
      data-id={linesInCustomer[index].id}
      onClick={handleSelectLine}
      >
      {linesInCustomer[index].use_name + " | " + linesInCustomer[index].number}
    </div>
    );
  }
  return lines;
}


  return (  
    <>
      {(
        props.isDesktop ? 
        <BlockCategoryDesktop 
          rows={rows}
          line={props.selectedLine}
          customer={selectedCustomer}
          isLinesListVisible={isLinesListVisible}
          setCustomerView={props.setCustomerView}
          rowCount={rowCount}
          loading={loading}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          handleAddRow={handleAddRow}
          setIsLinesListVisible={setIsLinesListVisible}
          changeSearchFilters={changeSearchFilters}
          handleEditBlockSubmit={handleEditBlockSubmit}
          displayDeletePopup={displayDeletePopup}
          handleDeleteRow={handleDeleteRow}
          displayEditPopup={displayEditPopup}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
          />
           :
        <BlockCategoryMobile 
          rows={rows} 
          line={props.selectedLine}
          customer={selectedCustomer}
          isLinesListVisible={isLinesListVisible}
          setCustomerView={props.setCustomerView}
          rowCount={rowCount}
          loading={loading}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          handleAddRow={handleAddRow}
          setIsLinesListVisible={setIsLinesListVisible}
          changeSearchFilters={changeSearchFilters}
          handleEditBlockSubmit={handleEditBlockSubmit}
          displayDeletePopup={displayDeletePopup}
          handleDeleteRow={handleDeleteRow}
          displayEditPopup={displayEditPopup}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
          handleSwitchClick={handleSwitchClick}
          getLinesList={getLinesList}
        />
      )}
      <Dialog open={dialogIsOpen} onClose={handleDialogClose} 
        >
        <DialogTitle>{t(lineFormInput.title)}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            value={lineFormInput.number}
            id="number"
            label={t("Phone Number")}
            type="string"
            fullWidth
            variant="standard"
            disabled={lineFormInput.action === ACTION_EDIT}
            required
            onChange={e => changeLineValue(e, 'number')}
            error={phoneErrorText !== ""}
            helperText={phoneErrorText}
          />
          <FormControlLabel control={<Switch id="active" onChange={e => changeLineValue(e)} />} label={t("Is Active")} />
          <FormControl fullWidth               
          sx={[
                {
                  mb: 2
              }
            ]}>
            <InputLabel id="call-type-label">{t("Call Type")}</InputLabel>
            <Select
              labelId="call-type-label"
              value={lineFormInput.call_type}
              onChange={e => changeLineValue(e, '', 'call_type')}
              id="call_type"
              label={t("Call Type")}
            >
              <MenuItem value={CALL_TYPE_OUT}>{t("Outgoing call")}</MenuItem>
              <MenuItem value={CALL_TYPE_IN}>{t("Incoming call")}</MenuItem>
              <MenuItem value={CALL_TYPE_ALL}>{t("Both")}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth
              sx={[
                {
                  mb: 2
              }
            ]}>
            <InputLabel id="call-type-label">{t("Status")}</InputLabel>
            <Select
              labelId="status-label"
              value={lineFormInput.status}
              onChange={e => changeLineValue(e, '', 'status')}
              id="status"
              label={t("Status")}
            >
              <MenuItem value={STATUS_BLOCKED}>{t("Blocked")}</MenuItem>
              <MenuItem value={STATUS_OPEN}>{t("Open")}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            value={lineFormInput.note}
            id="note"
            label={t("Note")}
            type="note"
            fullWidth
            variant="standard"
            onChange={e => changeLineValue(e)}
          />
        </DialogContent>
        <DialogActions> 
          <Button variant="outlined" onClick={handleDialogClose} size="large" sx={{ ml: 2}}>{t('Cancel')}</Button>
          <Button  variant="contained"onClick={
            () => {
              if(lineFormInput.id !== null){
                handleEditBlockSubmit(lineFormInput);
              }else{
                handleAddBlockSubmit();
              }
            }} size="large">{t('Submit')}</Button>
        </DialogActions>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoaderVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <Dialog open={deleteDialogIsOpen} onClose={handleDeleteDialogClose} 
        >
        <DialogContent>
          <div className='popup-content'>
            <div className='text'>{t("Do you want to delete the record?")}</div>
            <div className='controls'>
              <div className='proceed'
              onClick={() => {
                handleDeleteRow(lineToDelete);
                setLineToDelete(initialLine);
                handleDeleteDialogClose();
              }}
              >{t("Yes")}</div>
              <div className='cancel'
                onClick={handleDeleteDialogClose}
              >{t("Cancel")}</div>
            </div>
          </div>
        </DialogContent>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoaderVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </>
  );
}
