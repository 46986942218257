import React, { useState, useEffect } from 'react';
import { CALL_TYPE_IN, CALL_TYPE_OUT, STATUS_BLOCKED, STATUS_OPEN,  ALL, CALL_TYPE_ALL} from '../../../Constants';
import {IMPORT_HOVER_UPLOAD, IMPORT_HOVER_DOWNLOAD} from '../Category';
import Switch from '../../Switch';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import DesktopDataGrid from '../../DesktopDataGrid';
import Select from '../../Select';
import SelectWrapper from '../../SelectWrapper';


export default function Category(props) {
  const { t } = useTranslation();

  const inputNameRef = React.useRef(null);

  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
  const [importHover, setImportHover] = useState(null);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
        if(
          e.target.closest("body") === null 
        || e.target.closest(".body") !== null 
        || e.target.closest(".header") !== null
        || e.target.closest(".expansions-wrapper") !== null
        || e.target.closest("div[role='presentation']") !== null
        || e.target.closest(".grid-wrapper") !== null
        || e.target.closest(".add-form-wrapper") !== null
        ){
        return;
      }else{
        props.setIsAddFormVisible(false);
      }
    })
  }, []);

  const columns = [
    { field: 'number', headerName: t('Number')},
    { field: 'active', headerName: t('Is Active'),
      valueGetter: (row) => {
        return (
          row.active === 0 ? 
          <img src='design-images/no-mark.svg' /> : 
          <img src='design-images/yes-mark.svg' />
        );
      }
    },
    { field: 'status', headerName: t('Status'),
      valueGetter: (params) => {return (params.status === STATUS_BLOCKED ? t("Blocked") : t("Open"))}
    },
    { field: 'call_type', headerName: t('Call Type'),
      valueGetter: (params) => {
        let label = null;
        if(params.call_type === CALL_TYPE_OUT){
          label = t("Outgoing");
        }
        if(params.call_type === CALL_TYPE_IN){
          label = t("Incoming");
        }
        if(params.call_type === CALL_TYPE_ALL){
          label = t("Both");
        }
        return label;
      }
    },
    { field: 'note', headerName: t('Note')}
  ];

  if(props.selectedCategory.isOwner){
    columns.push(
      { 
        field: 'edit', headerName: '',
        renderCell: (cellValues) => {
          return (
            <svg 
            className='edit-category button' onClick={(e) => props.toggleExpandRow(cellValues.id)}
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
            <path d="M7.96094 19.1551V22.1313H10.9817L19.8911 13.3536L16.8703 10.3774L7.96094 19.1551ZM22.2272 11.052C22.5413 10.7425 22.5413 10.2425 22.2272 9.93301L20.3422 8.07589C20.028 7.76637 19.5205 7.76637 19.2064 8.07589L17.7322 9.52825L20.753 12.5044L22.2272 11.052Z" fill="white"/>
            </svg>
            
          );
        }
      }
    );
    columns.push(
      { 
        field: 'delete', headerName: '',
        renderCell: (cellValues) => {
          return (
              <svg 
              className='delete-category button' onClick={() => props.handleDeleteRow(cellValues)} 
              width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
              <path d="M20 10L10 20M20 20L10 10" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            );
        }
      }
    );
  }

  const renderRowExpansion = (cellValues) => {
    return <>
      <div className='item number'>
        <input type="text" 
          className='underline'
          name="number"
          value={cellValues.number}
          disabled="disabled"
          />
      </div>
      <div className='item'>
        <Switch 
          type="boolean"
          name="active"
          value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].active : cellValues.active}
          mainValue={1}
          handleClick={(newValue) => {
            props.handleChangeEditValue(newValue, "active", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
          }}
          style={{width: "50px"}}
          />
      </div>
      <div className='item'>
        <Switch 
          type="text"
          name="status"
          values={{
            [STATUS_BLOCKED]: t("Blocked"),
            [STATUS_OPEN]: t("Open") 
          }}
          value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].status : cellValues.status}
          mainValue={STATUS_OPEN}
          handleClick={(newValue) => {
            props.handleChangeEditValue(newValue, "status", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
          }}
          style={{width: "65px"}}
          />
      </div>
      <div className='item'>
        <Select values={{
          [CALL_TYPE_IN]: t("Incoming"),
          [CALL_TYPE_OUT]: t("Outgoing"),
          [CALL_TYPE_ALL]: t("Both")
        }} 
          value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].call_type : cellValues.call_type}
          handleClick={(newValue) => {
            props.handleChangeEditValue(newValue, "call_type", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
          }}
          />
      </div>
      <div className='item note'>
        <input type="text" 
            className='underline'
            name="number"
            data-id={cellValues.id}
            value={props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id].note : cellValues.note}
            onChange={(e) => {
              props.handleChangeEditValue(e.target.value, "note", {...cellValues, ...(props.editBlockValues.hasOwnProperty(cellValues.id) ? props.editBlockValues[cellValues.id] : {})});
            }}
            />
      </div>
      <div className='item save'>
        <img src='design-images/save-black.svg'
        onClick={(e) => {
          props.handleEditLineSubmit(cellValues.id);
        }}
        />
      </div>
    </>
  }

  return (  
    <>
      <div className='category-information-desktop'>
        <div className='top-wrapper'>
          <div className={'search-form'+(isSearchFormVisible ? '' :' hidden')}
          onClick={(e) => {
            e.stopPropagation();
          }}
          >
            <div className="close-button"
              onClick={() => {setIsSearchFormVisible(false)}}
            >
              <img src="design-images/close-black.svg" 
                className="close"
              />
           </div>

            <div className="focus-input"
              onClick={() => {inputNameRef.current.focus()}}
            >
              <input type="text"
                ref={inputNameRef}
                className='underline'
                placeholder={t("number")}
                name="number"
                onChange={(e) => {
                  props.changeSearchFilters(e.target.value, "number");
                }}
              />
            </div>

            <SelectWrapper
              name ={t("Is Active")}
              values={{
                [ALL]: t("Show All"),
                "1": t("Yes"),
                "0": t("No")
              }} 
                value={props.searchInput.active}
                handleClick={(newValue) => {
                  props.changeSearchFilters(newValue, "active");
                }}
            />

            <SelectWrapper
              name ={t("Status")}
              values={{
                [ALL]: t("Show All"),
                [STATUS_BLOCKED]: t("Blocked"),
                [STATUS_OPEN]: t("Open")
              }} 
              value={props.searchInput.status}
              handleClick={(newValue) => {
                props.changeSearchFilters(newValue, "status");
              }}
            />

            <SelectWrapper
              name ={t("Call Type")}
              style={{width: "110px"}}
              values={{
                  [ALL]: t("Show All"),
                  [CALL_TYPE_OUT]: t("Outgoing call"),
                  [CALL_TYPE_IN]: t("Incoming call")
                }} 
              value={props.searchInput.call_type}
              handleClick={(newValue) => {
                props.changeSearchFilters(newValue, "call_type");
              }}
            />
            
            <div className='search-button' 
              onClick={props.handleSearchSubmit}
            >
              <img src="design-images/search.svg" 
                className="button"
                />
            </div>
          </div>
          <div className={'title-wrapper'+(isSearchFormVisible ? ' hidden' : '')}>
            <div className='title'>
              <div className='text'
                onClick={()=>{
                  props.viewCategoriesList();
                }}
              >{t('Block categories')} &#62;</div>
              <div className='text current'>{props.selectedCategory.name}</div>
            </div>
            <div className='search-label'
                onClick={() => {setIsSearchFormVisible(true)}}>
              <div className='text'>{t("Search number in category")}</div>
              <img src="design-images/search-gray.svg" 
                className="button"
                  />
            </div>
          </div>
        </div>
        <div className={'title-wrapper secondary'+(isSearchFormVisible ? ' hidden' : '')}>
            <div className='text main'>{t("Description")}:</div>
            {
            (props.selectedCategory.note !== "") ?
            <>
              <div className='text'>{props.selectedCategory.note}</div>
              <div className='text separator'>|</div>
            </>
             :
              ''
            }
            <div className='text'>
              {(props.selectedCategory.isPublic === 1 ? t('Public') : t('Not public'))}
            </div>
            {(
              props.selectedCategory.valid === 1 ? 
              <>
                <div className='text separator'>|</div>
                <div className='text'>{t("Verified")}</div>
                <img src='design-images/yes-mark.svg' />
              </> : 
              ''
            )}
          </div>
        <div className='grid-wrapper'>
          <DesktopDataGrid 
            rowCount={props.rowCount}
            rows={props.rows}
            columns={columns}
            page={props.page}
            pageSize={props.pageSize}
            onPageChange={(newPage) => {
              props.setPage(newPage);
            }}
            onPageSizeChange={(newPageSize) => {
              props.setPageSize(newPageSize);
            }}
            onDeleteRows={() => {props.setDeleteDialogIsOpen(true)}}
            setSelectionIds={props.setSelectionIds}
            selectionIds={props.selectionIds}
            renderRowExpansion={renderRowExpansion}
            expandedRowIds={props.expandedRowIds}
            setExpandedRowIds={props.setExpandedRowIds}
            isRowHighlighted={(row) => {
              if(props.lineNumbersToHighlight.includes(row.number)){
                return true;
              }
            }}
            />
            <div className='controls'>
              {((props.selectionIds.length === 0) ? 
                <div className='button-add' 
                  onClick={
                    () => {
                      props.handleAddClick();
                    }}
                  >+ {t("Add a row")}</div> : ''
              )}
              {((props.selectionIds.length > 0) ? 
                <div className='button-delete' 
                  onClick={() => {props.setDeleteDialogIsOpen(true)}}
                  >- {t("Delete selected rows")}</div> : ''
              )}
              {
                (
                  importHover === null ?
                  <div className='button-import'
                  onMouseEnter={() => {setImportHover(IMPORT_HOVER_UPLOAD)}}
                  onMouseLeave={() => {setImportHover(null)}}
                  >
                    <div className='main'>
                      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.33185 1.00373L11.5702 5.24206M7.33185 1.00373L7.33185 11.5M7.33185 1.00373L3.09574 5.24075M13.5004 12.1256L13.5003 15.3278L0.985662 15.3277L0.985682 12.1257" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      {t("Import numbers file")}
                    </div>
                  </div> :
                  (
                    importHover === IMPORT_HOVER_UPLOAD ?
                    <div className='button-import hover upload'
                    onMouseLeave={() => {setImportHover(null)}}
                    >
                      <div className='main right'
                        onClick={props.onImportRows}
                      >
                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.33185 1.00373L11.5702 5.24206M7.33185 1.00373L7.33185 11.5M7.33185 1.00373L3.09574 5.24075M13.5004 12.1256L13.5003 15.3278L0.985662 15.3277L0.985682 12.1257" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {t("Import numbers file")}
                      </div>
                      <div className='extension'
                      onMouseEnter={() => {setImportHover(IMPORT_HOVER_DOWNLOAD)}}
                      >
                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.33296 11.4933L3.09463 7.25497M7.33296 11.4933L7.33296 0.997025M7.33296 11.4933L11.5691 7.25627M13.4993 12.1189L13.4992 15.3211L0.984559 15.321L0.984579 12.119" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                    </div> :
                    <div className='button-import hover download'
                    onMouseLeave={() => {setImportHover(null)}}
                    >
                      <div className='extension'
                      onMouseEnter={() => {setImportHover(IMPORT_HOVER_UPLOAD)}}
                      >
                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.33185 1.00373L11.5702 5.24206M7.33185 1.00373L7.33185 11.5M7.33185 1.00373L3.09574 5.24075M13.5004 12.1256L13.5003 15.3278L0.985662 15.3277L0.985682 12.1257" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <a href="/מספרים לחסימה.xlsx" className='main right'>
                        {t("Download import file sample")}
                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.33296 11.4933L3.09463 7.25497M7.33296 11.4933L7.33296 0.997025M7.33296 11.4933L11.5691 7.25627M13.4993 12.1189L13.4992 15.3211L0.984559 15.321L0.984579 12.119" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </a>
                    </div>
                  )
                )
              }
            </div>
        </div>
        <div className={'add-form-wrapper'+(props.isAddFormVisible ? "":" hidden")}>
        <div className='add-form'>
          <div className='item number'>
            <input type="text" 
              className='underline'
              name="number"
              value={props.addValue.number}
              onChange={(e) => {
                if(props.isValidValue(e.target.value, "number")){
                  props.setAddValue({...props.addValue, "number": e.target.value})
                }
              }}
              />
          </div>
          <div className='item'>
            <Switch 
              type="boolean"
              name="active"
              value={props.addValue.active}
              mainValue={1}
              handleClick={(newValue) => {
                props.setAddValue({...props.addValue, "active": newValue})
              }}
              style={{width: "50px"}}
              />
          </div>
          <div className='item'>
            <Switch 
              type="text"
              name="status"
              values={{
                [STATUS_BLOCKED]: t("Blocked"),
                [STATUS_OPEN]: t("Open") 
              }}
              value={props.addValue.status}
              mainValue={STATUS_OPEN}
              handleClick={(newValue) => {
                props.setAddValue({...props.addValue, "status": newValue})
              }}
              style={{width: "65px"}}
              />
          </div>
          <div className='item'>
            <Select values={{
                [CALL_TYPE_IN]: t("Incoming"),
                [CALL_TYPE_OUT]: t("Outgoing"),
                [CALL_TYPE_ALL]: t("Both")
              }} 
              value={props.addValue.call_type}
              handleClick={(newValue) => {
                props.setAddValue({...props.addValue, "call_type": newValue})
              }}
              />
          </div>
          <div className='item note'>
            <input type="text" 
                className='underline'
                name="number"
                value={props.addValue.note}
                onChange={(e) => {
                  props.setAddValue({...props.addValue, "note": e.target.value})
                }}
                />
          </div>
          <div className='item save'>
            <div className='button-wrapper'
              onClick={(e) => {
                props.handleAddLineSubmit();
              }}
            >
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5 1.5V17.5M17.5 9.5H1.5" stroke="#FFC700" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
