import React, {useState} from 'react';
import '../../styles.css';
import CategoriesGridWrapperDesktop from './Desktop/GridWrapper';
import CategoriesGridWrapperMobile from './Mobile/GridWrapper';


export default function CategoriesGridWrapper(props) {
  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);

  return (
      props.isDesktop ? 
      <CategoriesGridWrapperDesktop
      page={props.page}
      rows={props.rows}
      pageSize={props.pageSize}
      rowCount={props.rowCount}
      setPage={props.setPage}
      setPageSize={props.setPageSize}
      handleViewRow={props.handleViewRow}
      handleDeleteRow={props.handleDeleteRow}
      changeSearchFilters={props.changeSearchFilters}
      searchInput={props.searchInput}
      isSearchFormVisible={isSearchFormVisible}
      setIsSearchFormVisible={setIsSearchFormVisible}
      handleSearchSubmit={props.handleSearchSubmit}
      handleAddRow={props.handleAddRow}
      handleAddBelongsRows={props.handleAddBelongsRows}
      handleRemoveBelongsRows={props.handleRemoveBelongsRows}
      isUserBelongsToAllSelectedCategories={props.isUserBelongsToAllSelectedCategories}
      selectionIds={props.selectionIds}
      setSelectionIds={props.setSelectionIds}
      expandedRowIds={props.expandedRowIds}
      setExpandedRowIds={props.setExpandedRowIds}
      /> : 
      <CategoriesGridWrapperMobile
        page={props.page}
        rows={props.rows}
        pageSize={props.pageSize}
        rowCount={props.rowCount}
        setPage={props.setPage}
        setPageSize={props.setPageSize}
        handleViewRow={props.handleViewRow}
        handleDeleteRow={props.handleDeleteRow}
        changeSearchFilters={props.changeSearchFilters}
        searchInput={props.searchInput}
        isSearchFormVisible={isSearchFormVisible}
        setIsSearchFormVisible={setIsSearchFormVisible}
        handleSearchSubmit={props.handleSearchSubmit}
        handleAddRow={props.handleAddRow}
        handleAddBelongsRows={props.handleAddBelongsRows}
        handleRemoveBelongsRows={props.handleRemoveBelongsRows}
        isUserBelongsToAllSelectedCategories={props.isUserBelongsToAllSelectedCategories}
        selectionIds={props.selectionIds}
        setSelectionIds={props.setSelectionIds}
        expandedRowIds={props.expandedRowIds}
        setExpandedRowIds={props.setExpandedRowIds}
        />
    );
}
