import React from 'react';
import '../../../styles.css';
import Device from "../../Device";
import { useTranslation } from 'react-i18next';

export default function CustomerDevice(props) {
  const { t } = useTranslation();
  
  const render = (lineDevice) => {
    return(
    <>
    <div className='column right'>
      <div className='item'>
        <div className='field'>{t('Device Brand')}:</div>
        <div className='value'>{lineDevice.brand}</div>
      </div>
      <div className='item'>
        <div className='field'>{t('Device Model')}:</div>
        <div className='value'>{lineDevice.model}</div>
      </div>
    </div>
    <div className='column left'>
      <div className='item'>
        <div className='field'>{t('Device IMEI')} {"IMEI"}:</div>
        <div className='value'>{lineDevice.imei}</div>
      </div>
    </div>
    </>);
  }

  return <Device customer={props.customer} line={props.line}  render={render} />

}
