import { t } from 'i18next';
import React, {useState, useEffect} from 'react';
import {Select, MenuItem} from '@mui/material';

export default function DesktopDataGrid(props) {
  const [expandedRowTops, setExpandedRowTops] = useState({});

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
        if(
          e.target.closest("body") === null 
        || e.target.closest(".body") !== null 
        || e.target.closest(".header") !== null
        || e.target.closest(".expansions-wrapper") !== null
        || e.target.closest(".expansion") !== null
        || e.target.closest("div[role='presentation']") !== null
        ){
        return;
      }else{
        if(props.hasOwnProperty("setExpandedRowIds")){
          props.setExpandedRowIds([]);
        }
      }
    })
  }, []);
  
  useEffect(() => {
    if(props.hasOwnProperty("expandedRowIds")){
      let newExpandedRowTops = {};
      let element = null;
      for(let index=0; index<props.expandedRowIds.length; index++){
        element = document.querySelector(".row-wrapper[data-id='"+props.expandedRowIds[index]+"']");
        if(element === null){
          newExpandedRowTops[props.expandedRowIds[index]] = 0;
        }else{
          newExpandedRowTops[props.expandedRowIds[index]] = element.offsetTop-element.offsetHeight;
        }
      }
      setExpandedRowTops(newExpandedRowTops);
    }
  }, [props.expandedRowIds]);

  const getGridColumns = () => {
    let columns = [];
    for (let index = 0; index < props.columns.length; index++) {
      columns.push(
      <div 
        className='column' 
        key={props.columns[index].field}
        data-field={props.columns[index].field}>
        {props.columns[index].headerName}
      </div>
      );
    }
    return columns;
  }

  const getSelectionBox = (rowId) => {
    return (
      <div className='selection-box item'
      key={rowId}
      data-id={rowId}
      onClick={() => {
        props.setSelectionIds(toggleValues(rowId, props.selectionIds))
      }}
      >
        {(
          !props.selectionIds.includes(rowId) ?
          <img src='design-images/grid-box.svg' /> :
          <>
            <img src='design-images/grid-box-red.svg' />
            <img 
              className='mark'
              src='design-images/yes-mark.svg' />
          </>
        )}
      </div>
    )
  }
  
  const getGridRows = () => {
    let rows = [];
    for (let index = 0; index < props.rows.length; index++) {
      rows.push(
        <div 
        data-id={props.rows[index].id}
        className={'row-wrapper' + 
            (props.hasOwnProperty("expandedRowIds") && props.expandedRowIds.includes(props.rows[index].id) ? ' expanded' : '') +
            (props.hasOwnProperty("selectionIds") && props.selectionIds.includes(props.rows[index].id) ? ' selected' : '')}
        key={"row-wrapper"+props.rows[index].id}>
          {(
              props.hasOwnProperty("selectionIds") ? 
                getSelectionBox(props.rows[index].id)
                : ''
            )}
          <div 
          className={'row'+((props.hasOwnProperty("isRowHighlighted")) && (props.isRowHighlighted(props.rows[index])) ? ' highlighted' : '')}
          key={"row"+props.rows[index].id}
          data-id={props.rows[index].id}
          >
            {getRow(props.rows[index])}
          </div>
          {(
            props.hasOwnProperty("renderExpansion")
            && props.hasOwnProperty("expandedRowIds") 
            && props.expandedRowIds.includes(props.rows[index].id)
            ?
            <div className='expansion'>
              {props.renderExpansion(props.rows[index])}
            </div> : ''
          )}
        </div>
        );
    }
    return rows;
  }

  const toggleExpandRow = (cellValues) => {
    props.setExpandedRowIds(toggleValues(cellValues.id, props.expandedRowIds))
  }
 
  const toggleValues = (value, values) => {
    if(values.includes(value)){
      let newValues = [...values];
      newValues.splice(newValues.indexOf(value), 1);
      return newValues;
    }else{
      return[...values, value];
    }
  }

  const gridFunctions = {
    toggleExpandRow: toggleExpandRow
  };

  const getRow = (rowData) => {
    let columnsObject = props.columns.reduce((acc, column) => ({...acc,[column.field]:column}),{});
    let columnsToRender = props.columns.filter((itemValue)=>{return itemValue.hasOwnProperty("renderCell");});

    let row = [];
    for (let rowKey in columnsObject) {
      if(!rowData.hasOwnProperty(rowKey)
          && columnsToRender.hasOwnProperty(rowKey)){
        continue;
      }
      row.push(
        <div 
        className='item' 
        key={rowKey+"-"+rowData.id}
        data-field={rowKey}
        >
          {(
            columnsObject[rowKey].hasOwnProperty("valueGetter") ? 
            columnsObject[rowKey].valueGetter(rowData) :
            (
              columnsObject[rowKey].hasOwnProperty("renderCell") ? 
              columnsObject[rowKey].renderCell(rowData, gridFunctions) :
              rowData[rowKey] 
            )
          )}
        </div>);
    }
  
    return row;
  }

  const getExpansions = () => {
    let rows = [];
    for (let index = 0; index < props.rows.length; index++) {
      if(props.expandedRowIds.includes(props.rows[index].id)){
        rows.push(
          <div className='expansion' 
            key={"expansion-"+props.rows[index].id}
            data-id={props.rows[index].id}
            style={{
              top: expandedRowTops[props.rows[index].id]+"px"
            }}
            >
              {props.renderRowExpansion(props.rows[index])}
        </div>
         );
      }
    }
    return rows;
  }

  return (  
    <div className={'desktop-grid-wrapper'+(props.hasOwnProperty("selectionIds") ? ' has-selections' : '')}>
      <div className='desktop-grid-container'>
        <div className='desktop-grid'>
          <div className='header'>
            {getGridColumns()}
          </div>
          <div className='body'>
            {(
              props.rows.length === 0 ?
              <div className='no-rows'>{t("No rows found")}</div> :
              getGridRows()
            )}
          </div>
        </div>
      </div>
      {(
          props.hasOwnProperty("renderRowExpansion") ?
          <div className='expansions-wrapper'>
            {getExpansions()}
          </div> : ''
        )}
      <div className='pagination'>
          <div className='rows-per-page'>
            <div className='text'>{t("Rows per page")}</div>
            <div className='dropdown'>
              <Select
                size="small"
                value={props.pageSize}
                onChange={(e) => props.onPageSizeChange(e.target.value)}
                className="field"
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              >
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="50">50</MenuItem>
              </Select>
            </div>
          </div>
          {(
            props.page > 0 ?
            <div className='page-arrow prev'
              onClick={() => {props.onPageChange(props.page-1)}}
            >{"<"}</div> : ''
          )}
          <div className='page-info'>
            {t("{{first}}-{{last}} out of {{total}}", {
              first: (props.page*props.pageSize)+1,
              last: Math.min((props.page+1)*props.pageSize, props.rowCount),
              total: props.rowCount
            })}
          </div>
          {(
            props.page+1 < Math.ceil(props.rowCount / props.pageSize) ?
            <div className='page-arrow next'
              onClick={() => {props.onPageChange(props.page+1)}}
            >{">"}</div> : ''
          )}
        </div>
    </div>
  );
}
