import React, {useState} from 'react';
import SendNumber from '../SendNumber';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../Constants';
import { Box, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function AntiSpam(props) {
  const MESSAGE_LOCATION_SETTING = "setting";
  const MESSAGE_LOCATION_WHITELIST = "whitelist";
  const MESSAGE_LOCATION_WHITELIST_IMPORT = "whitelist_import";

  const { t } = useTranslation();
  
  const [loading, setLoading] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);
  const [successMessageLocation, setSuccessMessageLocation] = useState(MESSAGE_LOCATION_SETTING);

  const [showDetailedErrorMessage, setShowDetailedErrorMessage] = useState(false);
  const defaultDetailedErrorMessage = t("The operation was successfull");
  const [detailedErrorMessage, setDetailedErrorMessage] = useState(defaultSuccessMessage);
  const [detailedErrorMessageLocation, setDetailedErrorMessageLocation] = useState(MESSAGE_LOCATION_SETTING);

  return <>
      <div className='anti-spam-wrapper'>
        {(
        showSuccessMessage && successMessageLocation === MESSAGE_LOCATION_WHITELIST ?
        <Box className="success-message" sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between', alignItems: "center"  }}>
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box> : ''
      )}
      <SendNumber
        type="whitelist"
        apiUrl={API_URL + '/antiSpam/whiteList'}
        setLoading={setLoading}
        messageLocation={MESSAGE_LOCATION_WHITELIST}
        setSuccessMessageLocation={setSuccessMessageLocation}
        setShowSuccessMessage={setShowSuccessMessage}
        title={t("Whitelist a number")}
        buttonTitle={t('Submit whitelist')}
        />
      {(
        showSuccessMessage && successMessageLocation === MESSAGE_LOCATION_WHITELIST_IMPORT ?
        <Box className="success-message" sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between', alignItems: "center"  }}>
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box> : ''
      )}
      {(
        showDetailedErrorMessage && detailedErrorMessageLocation === MESSAGE_LOCATION_WHITELIST_IMPORT ?
        <Box className="error-message" sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between', alignItems: "center"  }}>
          <div>
            <div>{t('Errors')}:</div>
            <div>{detailedErrorMessage}</div>
          </div>
          <IconButton onClick={() => setShowDetailedErrorMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box> : ''
      )}
      <SendNumber
        type="whitelist"
        isMany={true}
        apiUrl={API_URL + '/antiSpam/whiteListImport'}
        messageLocation={MESSAGE_LOCATION_WHITELIST_IMPORT}
        setSuccessMessageLocation={setSuccessMessageLocation}
        setShowSuccessMessage={setShowSuccessMessage}
        setDetailedErrorMessageLocation={setDetailedErrorMessageLocation}
        setShowDetailedErrorMessage={setShowDetailedErrorMessage}
        setDetailedErrorMessage={setDetailedErrorMessage}
        title={t("Whitelist numbers")}
        note={t("Please paste a list of numbers")}
        buttonTitle={t('Submit whitelist')}
        setLoading={setLoading}
        />
      </div>
  </>
}
