import React, {useState} from 'react';
import '../styles.css';
import Select from './Select';


export default function SelectWrapper(props) {
  const [isFocus, setIsFocus] = useState(false);

  return (  
    <div className='search-item'
      onClick={() => {
        setIsFocus(!isFocus);
      }}
    >
      <div className='text'>{props.name}</div>
      <Select 
        values={props.values} 
        value={props.value} 
        handleClick={props.handleClick}
        isFocus={isFocus}
        selectClicked={(newValue) => {
          setIsFocus(newValue);
        }}
      />
    </div>
  );
}
