import React from 'react';
import WatchSystemDesktop from './Desktop/WatchSystem';
import WatchSystemMobile from './Mobile/WatchSystem';

export default function WatchSystem(props) {
  return (
    props.isDesktop ?
    <WatchSystemDesktop 
      entityType={props.entityType} 
      customer={props.customer} 
      line={(props.entityType === "line" ? props.line : null)}
      setCustomerView={props.setCustomerView}
    ></WatchSystemDesktop> : 
    <WatchSystemMobile 
      entityType={props.entityType} 
      customer={props.customer} 
      line={(props.entityType === "line" ? props.line : null)}
      setCustomerView={props.setCustomerView}
    ></WatchSystemMobile> 
  )
}
