import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT, LIABILITY_POPUP_APPROVED, CATEGORY_VIEW_CATEGORIES_LIST, CATEGORY_VIEW_CATEGORY, APP_NAME, APP_NAME_SIMKEHILOT } from '../../Constants';
import { Backdrop, CircularProgress, Typography} from '@mui/material';
import {Stack, Button, Box, Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles.css';
import cookie from "js-cookie";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import {handleApiErrorMessage} from "../../Helper";
import Switch from "../Switch";
import Category from "../Categories/Category";
import CategoriesGridWrapper from '../Categories/GridWrapper';


export default function CategoriesManagement(props) {
  const { t } = useTranslation();
  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const approvedLiablilityPopup = (localStorage.getItem(LIABILITY_POPUP_APPROVED) === "1");
  const [liabilityDialogIsOpen, setLiabilityDialogIsOpen] = useState(!approvedLiablilityPopup);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [removeBelongsDialogIsOpen, setRemoveBelongsDialogIsOpen] = useState(false);
  const [view, setView] = useState(CATEGORY_VIEW_CATEGORIES_LIST);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  
  const initalSelectedCategoryValue = {
    id: null,
    name: "",
    note: "",
    public: 1,
    privacy_level: 0,
    isOwner: 1,
    myJoin: 0,
    valid: 0,
    active_setting: 0
  };
  const [selectedCategory, setSelectedCategory] = useState(initalSelectedCategoryValue);
  const [selectedCategoryToDelete, setSelectedCategoryToDelete] = useState(initalSelectedCategoryValue);
  const [newCategoryValues, setNewCategoryValues] = useState(initalSelectedCategoryValue);

  const kehilaCategoryData = {
    id: 0,
    name: (APP_NAME === APP_NAME_SIMKEHILOT ? t('Kehila Category') : t('General category')),
    note: '',
    public: 0,
    create_date: '',
    isOwner: 1,
    myJoin: 1,
    valid: 1,
    active_setting: 0
  };

  const [selectionIds, setSelectionIds] = useState([]);

  const initialSearchInput = {
    name : "",
    onlyOwner: false,
    onlyMy: false,
    onlyValid: false
  };
  const [searchInput, setSearchInput] = useState(initialSearchInput);

  const searchInputTypes = {
    name: "string",
    onlyOwner: "boolean",
    onlyMy: "boolean",
    onlyValid: "boolean"
  };

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const DEAFULT_PAGE_SIZE = 50;
  const [pageSize, setPageSize] = useState(DEAFULT_PAGE_SIZE);

  const [isUserBelongsToAllSelectedCategories, setIsUserBelongsToAllSelectedCategories] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(loading){
      return () => {};
    }
    refreshCategoriesData();
  }, [page, pageSize, selectedCategory.id, view]);

  useEffect(() => {
    if(loading){
      return () => {};
    }
    for(let index = 0; index < rows.length; index++){
      if(!selectionIds.includes(rows[index].id)){
        continue;
      }
      if(rows[index].myJoin !== 1){
        setIsUserBelongsToAllSelectedCategories(false);
        return () => {};
      }
    }
    setIsUserBelongsToAllSelectedCategories(true);
  }, [selectionIds, rows]);

  
  const refreshCategoriesData = (pageToStart = null) => {
    if(pageToStart === null){
      pageToStart = page;
    }
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    let currentPageSize = pageSize;
    if(pageToStart === 0){
      currentPageSize = pageSize-1;
    }
    let currentStart = pageToStart*pageSize;
    if(pageToStart > 0){
      currentStart = currentStart-1;
    }

    axios.post(API_URL + '/categories/list', 
    {
      start: currentStart, 
      limit: currentPageSize, 
      search: searchInput.name, 
      onlyOwner: searchInput.onlyOwner,
      onlyMy: searchInput.onlyMy,
      onlyValid: searchInput.onlyValid
    }, 
    {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("list"))){
          let categoriesList = data.list;
          if(pageToStart === 0){
            kehilaCategoryData.active_setting = data.main_community_categories.active_setting;
            categoriesList.unshift(kehilaCategoryData);
          }
          setRows(categoriesList);
          setRowCount(data.totalCount+1);
          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
    });
  };

  const handleViewRow = (cellValues) => {
    setSelectedCategory({
      id: cellValues.id,
      name: cellValues.name,
      note: cellValues.note,
      isOwner: Number.parseInt(cellValues.isOwner),
      myJoin: Number.parseInt(cellValues.myJoin),
      valid: Number.parseInt(cellValues.valid),
      public: Number.parseInt(cellValues.public),
      privacy_level: Number.parseInt(cellValues.privacy_level),
      active_setting: Number.parseInt(cellValues.active_setting)
    });
    setView(CATEGORY_VIEW_CATEGORY);
  };
  
  const handleDeleteRow = (cellValues) => {
    setSelectedCategoryToDelete({
      id: cellValues.id,
      name: cellValues.name
    });
    setDeleteDialogIsOpen(true);
  };

  const handleRemoveBelongsRows = async () => {
    setRemoveBelongsDialogIsOpen(true);
  }

  const handleRemoveBelongsRowsSubmit = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    if(selectionIds.length === 0){
      alert(t('No lines selected'));
      return;
    }else{
      let nonRemovedRows = rows.filter((row) => (!selectionIds.includes(row.id)));
      if(nonRemovedRows.length === rows.length){
        alert(t('No lines selected'));
        return;
      }
    }
    
    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }
    
      const data = {
        ids: selectionIds.map((id) => {return ''+id;})
      };

      let apiUrl = API_URL + '/categories/remove';

      axios.post(apiUrl, data, {headers})
      .then(
         (responseData) => {
          if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
            refreshCategoriesData();
            setSelectionIds([]);
          }else{
            if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      });
  };

  const handleAddBelongsRows = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    if(selectionIds.length === 0){
      alert(t('No lines selected'));
      return;
    }else{
      let nonRemovedRows = rows.filter((row) => (!selectionIds.includes(row.id)));
      if(nonRemovedRows.length === rows.length){
        alert(t('No lines selected'));
        return;
      }
    }
    
    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }
    
    const data = {
      ids: selectionIds.map((id) => {return ''+id;})
    };

    let apiUrl = API_URL + '/categories/add';

    axios.post(apiUrl, data, {headers})
    .then(
        (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          setSelectionIds([]);
          refreshCategoriesData();
        }else{
          if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
    });
  };

  const changeSearchFilters = (newValue, fieldName) => {
    let newSearchInput = {
      name : searchInput.name,
      onlyOwner: searchInput.onlyOwner,
      onlyMy: searchInput.onlyMy,
      onlyValid: searchInput.onlyValid
    };
    if(searchInputTypes[fieldName] === "boolean"){
      newValue = (newValue === "true" || newValue === true  ? true : false);
    }
    newSearchInput[fieldName] = newValue;
    
    setSearchInput(newSearchInput);
  };

  const handleSearchSubmit = (e) => {
    refreshCategoriesData(0);
  };

  const handleAddRow = () => {
    setNewCategoryValues(initalSelectedCategoryValue);
    setIsNewCategoryDialogOpen(true);
  }


  const handleCloseLiabilityDialog = () => {
    localStorage.setItem(LIABILITY_POPUP_APPROVED, "1");
    setLiabilityDialogIsOpen(false);
  }

  const handleDeleteRowSubmit = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/categories/'+selectedCategoryToDelete.id+'/delete';

    axios.get(apiUrl, {headers})
    .then(
        (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          refreshCategoriesData();
          setLoading(false);
          setDeleteDialogIsOpen(false);
        }else{
          if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
    });
  };

  const handleChangeNewCategoryValue = (newValue, changedField) => {
    if(newValue === newCategoryValues[changedField]){
      return;
    }
    const newField = {[changedField] : newValue};
    setNewCategoryValues({...newCategoryValues, ...newField});
  }

  const createCategory = async (categoryData) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/categories/create';

    setLoading(true);

    await axios.post(apiUrl, {new_obj: categoryData}, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK && responseData.data.hasOwnProperty('newCategorieId')){
          setLoading(false);
          setIsNewCategoryDialogOpen(false);
          setNewCategoryValues(initalSelectedCategoryValue);
          setSelectedCategory(initalSelectedCategoryValue);
        }else{
          if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
            errorMessage = responseData.data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
    });

    refreshCategoriesData();
  };

  const handleCreateNewCategory = () => {
    const newCategoryData = {
      name: newCategoryValues.name,
      note: newCategoryValues.note,
      public: newCategoryValues.public,
      privacy_level: newCategoryValues.privacy_level
    };

    createCategory(newCategoryData);
  }

  return (  
    <>
    <div className="notice-message categories">
      <div className='text'>
        <div className='main'>{t("Dear community manager, please note!")}</div>
        <div>{t("The use of the categories is under your sole responsibility. {{company}} is not repsonsilbe of the categories and their listings.", {company: t(APP_NAME)})}</div>
      </div>
      <img src='design-images/alert-orange.svg' />
    </div>
    {(
      view === CATEGORY_VIEW_CATEGORIES_LIST ? 
      <CategoriesGridWrapper
        isDesktop={props.isDesktop} 
        setView={setView}
        handleViewRow={handleViewRow}
        handleDeleteRow={handleDeleteRow}
        page={page}
        rows={rows}
        pageSize={pageSize}
        rowCount={rowCount}
        setPage={setPage}
        setPageSize={setPageSize}
        changeSearchFilters={changeSearchFilters}
        searchInput={searchInput}
        handleSearchSubmit={handleSearchSubmit}
        handleAddRow={handleAddRow}
        handleAddBelongsRows={handleAddBelongsRows}
        handleRemoveBelongsRows={handleRemoveBelongsRows}
        isUserBelongsToAllSelectedCategories={isUserBelongsToAllSelectedCategories}
        selectionIds={selectionIds}
        setSelectionIds={setSelectionIds}
        expandedRowIds={expandedRowIds}
        setExpandedRowIds={setExpandedRowIds}
      />  :
      <Category 
        isDesktop={props.isDesktop}
        category={selectedCategory}
        viewCategoriesList={() => {setView(CATEGORY_VIEW_CATEGORIES_LIST)}}
        loading={loading}
        setLoading={setLoading}
         />
    )}
    <Dialog open={deleteDialogIsOpen} onClose={() => {setDeleteDialogIsOpen(false)}} 
    fullWidth
      >
      <DialogTitle>
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
          <div>{t("Remove the category {{category}}", {category: selectedCategoryToDelete.name})}</div>
          <IconButton onClick={() => {setDeleteDialogIsOpen(false)}}>
            <CloseIcon />
          </IconButton>
        </Box>
        </DialogTitle>
      <DialogContent>
        <Box sx={{mb: 2}}>
          <Typography variant="p" className="red-bold">{t('This action will remove the category permanently! All customers in this category will be deleted!')}</Typography>
        </Box>
        <Box sx={{mb: 2}}>
          <Typography variant="p">{t('Are you sure that you want to proceed with the action?')}</Typography>
        </Box>
        <Stack direction="row" spacing={1}
          sx={[
            {
              mb: 2
          }
        ]}
          >
            <Button size="medium" onClick={handleDeleteRowSubmit}
                  sx={[
                  {
                    bgcolor: 'red',
                    color: 'white',
                    ml: 2
                },
                {
                  '&:hover': {
                    bgcolor: '#ff7961',
                    color: 'white'
                  },
                }
              ]}
            >
              {t('Yes, proceed')}
            </Button>
            <Button 
            variant="contained"
            color="primary"
            size="medium" onClick={() => {setDeleteDialogIsOpen(false)}}>
                {t('Cancel')}
            </Button>
          </Stack>
      </DialogContent>
    </Dialog>
    <Dialog open={liabilityDialogIsOpen} 
      className="liability-popup popup"
        >
        <div className='title'>
          <div className='text-wrapper'>
            <img src='design-images/alert-orange.svg' />
            <div className='text'>{t("Dear community manager, please note!")}</div>
          </div>
        </div>
        <div className='content'>
          <div className='text'>
          {t("The use of the categories is under your sole responsibility. {{company}} is not repsonsilbe of the categories and their listings.", {company: t(APP_NAME)})}
          </div>
          <div className='controls'>
            <div className='proceed'
              onClick={handleCloseLiabilityDialog}
              >
                {t("Submit")}
            </div>
          </div>
        </div>
    </Dialog>
    <Dialog open={isNewCategoryDialogOpen} 
      onClose={() => {setIsNewCategoryDialogOpen(false);}}
      className="new popup"
        >
        <div className='title'>
          <div className='text-wrapper'>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.19531 1.80469V13.8047M13.1953 7.80469H1.19531" stroke="#FFC700" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div className='text'>{t("Create new category")}</div>
          </div>
        </div>
        <div className='content'>
          <input type="text"
            className='underline'
            placeholder={t("Name")}
            value={newCategoryValues.name}
            onChange={(e) => {
              handleChangeNewCategoryValue(e.target.value, "name");
            }}
            />
          <input type="text"
            className='underline'
            placeholder={t("Description")}
            value={newCategoryValues.note}
            onChange={(e) => {
              handleChangeNewCategoryValue(e.target.value, "note");
            }}
            />
          <Switch 
              type="text"
              name="public"
              values={{
                "1": t("Public"),
                "0": t("Private") 
              }}
              value={""+newCategoryValues.public}
              mainValue={"1"}
              handleClick={(newValue) => {
                handleChangeNewCategoryValue(newValue, "public");
              }}
            />
          <div className='switch-wrapper'>
            <Switch 
              type="text"
              name="privacy_level"
              values={{
                "1": t("Hide"),
                "0": t("Show") 
              }}
              value={""+newCategoryValues.privacy_level}
              mainValue={"0"}
              handleClick={(newValue) => {
                handleChangeNewCategoryValue(newValue, "privacy_level");
              }}
            />
            <div className='text'>{t("the numbers in the list")}</div>
          </div>
          <div className='controls'>
            <img src='design-images/save-black.svg'
              onClick={handleCreateNewCategory}
             />
          </div>
        </div>
    </Dialog>
    <Dialog open={removeBelongsDialogIsOpen} 
      onClose={() => {
        setRemoveBelongsDialogIsOpen(false);
      }}
      className="confirm-dialog popup"
        >
        <div className='title'>
          <div className='text'>{t("Do you want to remove yourself from the category?")}</div>
        </div>
        <div className='content'>
          <div className='controls'>
              <div className='proceed'
              onClick={
                () => {
                  setRemoveBelongsDialogIsOpen(false);
                  setLoading(true);
                  handleRemoveBelongsRowsSubmit();
                }}
              >{t("Yes")}</div>
              <div className='cancel'
                onClick={() => {setRemoveBelongsDialogIsOpen(false)}}
              >{t("Cancel")}</div>
            </div>
        </div>
    </Dialog>
    <Backdrop
      sx={{ color: '#fff', zIndex: 9999}}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
}
