import React, {useState, useMemo} from 'react';
import { Button, TextField} from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import { API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../Constants';
import cookie from "js-cookie";
import axios from "axios";
import '../styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../features/user";
import {handleApiErrorMessage} from "../Helper";
import { showErrorDialog } from "../features/errorDialog";
import countryList from 'react-select-country-list';

export default function SendNumber(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const countryOptions = useMemo(() => countryList().getValues(), []);
  
  const [addNumber, setAddNumber] = useState('');
  const [addNumbers, setAddNumbers] = useState([]);
  const [addNumberComment, setAddNumberComment] = useState('');
  const [countryCode, setCountryCode] = useState('IL');

  const handleSetNumber = (inputValue) => {
    let numericValue = inputValue.replace(/[^\+\d]/g,'');
    setAddNumber(numericValue);
  }

  const handleSetNumbers = (inputValue) => {
    const numberLines = inputValue.split(/\r?\n/);

    let numberLinesFixed = [];
    let numericValue = null;

    for(let i=0; i<numberLines.length; i++){
      numericValue = numberLines[i].replace(/[^\+\d]/g,'');
      numberLinesFixed.push(numericValue);
    }
    setAddNumbers(numberLinesFixed);
  }

  const handleSubmitReportNumber = () => {
    if(props.isMany === true){
      if(addNumbers.length === 0){
        return;
      }
    }else{
      if(addNumber === ""){
        return;
      }
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let params = {
      note: addNumberComment,
      countryCode: countryCode
    };
    if(props.isMany === true){
      params['numbers'] = addNumbers;
    }else{
      params['number'] = addNumber;
    }

    props.setLoading(true);

    axios.post(props.apiUrl,
    params,
    {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK 
        && data.hasOwnProperty("status") && (data['status'] === true))){
          if(props.isMany === true){
            setAddNumbers([]);
          }else{
            setAddNumber("");
          }
          setAddNumberComment("");
          setCountryCode("IL");
          props.setLoading(false);

          if(props.isMany){
            if(data.errors && Object.keys(data.errors).length){
              let errString = "";
              for(const errKey in data.errors){
                if(data.errors.hasOwnProperty(errKey)){
                  errString = errString + errKey + ": " + data.errors[errKey] + ", ";
                }
              }
              errString = errString.substring(0, errString.length-2);
              props.setDetailedErrorMessageLocation(props.messageLocation);
              props.setDetailedErrorMessage(errString);
              props.setShowDetailedErrorMessage(true);
            }else{
              props.setSuccessMessageLocation(props.messageLocation);
              props.setShowSuccessMessage(true);
            }
          }else{
            props.setSuccessMessageLocation(props.messageLocation);
            props.setShowSuccessMessage(true);
          }
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          props.setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        props.setLoading(false);
    });
  };


  return ( 
      <div className='setting-wrapper'>
        <div className='item'>
          <div className='title strong'>{props.title}</div>
          <div className='content'>
            <div className='line'>
              {(
                props.isMany === true ?
                <>
                <div className='sub-title'>{t("Numbers to report:")}</div>
                {(
                  typeof props.note !== 'undefined' ? 
                  <div className='note'>{props.note}</div> : ''
                )}
                <div className='value'>
                  <textarea className="numbers"
                  value={addNumbers.join("\n")}
                  onChange={(e) => {
                    handleSetNumbers(e.target.value);
                    }}
                  />
                </div></> :
                <>
                <div className='sub-title'>{t("Number to report:")}</div>
                <div className='value'>
                  <TextField className="input" size="small" 
                  value={addNumber}
                  onChange={(e) => {
                    handleSetNumber(e.target.value);
                    }}
                  />
                </div></>
              )}
            </div>
            <div className='line'>
              <div className='title'>{t("Report comment:")}</div>
              <div className='value'>
                <TextField className="input" size="small" 
                value={addNumberComment}
                onChange={(e) => {
                  setAddNumberComment(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className='line'>
              <div className='title'>{t("Country code:")}</div>
              <div className='value'>
                <Select
                  size="small"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  id="search-country-code"
                  sx={{width: {
                    lg: "210px"
                  }
                  }}
                >
                  {countryOptions.map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmitReportNumber()}
              className="agree"
          >
              {props.buttonTitle}
          </Button>
        </div>
      </div>
  );
}
