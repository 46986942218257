import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../../Constants';
import '../../styles.css';
import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import {handleApiErrorMessage} from "../../Helper";
import CustomerDesktop from './Desktop/Customer';
import CustomerMobile from './Mobile/Customer';

export default function Customer(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  useEffect(() => {
    if(loading){
      return () => {};
    }
    refreshLinesData();
  }, [page, pageSize]);


  useEffect(() => {
    document.body.addEventListener('click', (e) => {
        if(e.target.closest(".body") !== null 
        || e.target.closest(".header") !== null
        || e.target.closest(".expansions-wrapper") !== null
        ){
        return;
      }else{
        setExpandedRowIds([]);
      }
    })
  }, []);
  

  const refreshLinesData = (pageToStart = null) => {
    if(pageToStart === null){
      pageToStart = page;
    }
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    axios.post(API_URL + '/getLinesInCustomers', {customer_id: props.customer.id, start: pageToStart*pageSize, limit: pageSize}, {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("lines"))){
          setRows(data.lines);
          setRowCount(data.totalCount);
          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
    });
  };


  const handleViewWatchProfile = (cellValues) => {
    props.setSelectedLine({
      id: cellValues.id,
      number: cellValues.number,
      use_name: cellValues.use_name
    });
  };

  return (<>
    {(props.isDesktop ? 
    <CustomerDesktop 
      customer={props.customer} 
      setCustomerView={props.setCustomerView}
      selectedLine={props.selectedLine}
      resetSelectedLine={props.resetSelectedLine}
      setSelectedLine={props.setSelectedLine}
      rowCount={rowCount}
      rows={rows}
      loading={loading}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      handleViewWatchProfile={handleViewWatchProfile}
      handleViewCustomerWatchProfile={props.handleViewCustomerWatchProfile}
      expandedRowIds={expandedRowIds}
      setExpandedRowIds={setExpandedRowIds}
      
    /> :
    <CustomerMobile 
      customer={props.customer} 
      setCustomerView={props.setCustomerView}
      rowCount={rowCount}
      rows={rows}
      loading={loading}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      handleViewWatchProfile={handleViewWatchProfile}
      handleViewCustomerWatchProfile={props.handleViewCustomerWatchProfile}
      expandedRowIds={expandedRowIds}
      setExpandedRowIds={setExpandedRowIds}
    />)}
    </>
  );
}
