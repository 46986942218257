import React, {useState} from 'react';
import {Select, MenuItem} from '@mui/material';
import '../../../styles.css';
import MobileDataGrid from '../../MobileDataGrid';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_VIEW_BLOCK, CUSTOMER_VIEW_CUSTOMER } from '../../../Constants';


export default function CustomersGridWrapperMobile(props) {
  const { t } = useTranslation();
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const columns = [
    { field: 'name', headerName: t('Customer Name')},
    { field: 'phone', headerName: t('Number')},
    { field: 'update_date', headerName: t('Last Updated at')},
    { 
      field: 'last_out_call', 
      headerName: t('Last call'),
      renderCell: (params) => {
        return (
          <div style={{ color: (((new Date() - new Date(params.last_out_call)) / (1000 * 60 * 60 * 24)) > 5 && localStorage.getItem('show_red_inactive_lines') === "true") ? "red" : "black"}}>
            {params.last_out_call}
          </div>
        );
      }
    }
  ];

  const handleViewCustomer = (cellValues) => {
    props.setSelectedCustomer({
      id: cellValues.id,
      name: cellValues.name,
      phone: cellValues.phone,
      update_date: cellValues.update_date,
      last_out_call: cellValues.last_out_call,
    });
    props.setCustomerView(CUSTOMER_VIEW_CUSTOMER);
  };

  const handleViewCustomerBlockCategory = (cellValues) => {
    props.setSelectedCustomer({
      id: cellValues.id,
      name: cellValues.name,
      phone: cellValues.phone,
      update_date: cellValues.update_date,
      last_out_call: cellValues.last_out_call,
    });
    props.setCustomerView(CUSTOMER_VIEW_BLOCK);
  };

  const renderRowExpansion = (cellValues) =>{
    return <>
      <div className='main'>
        <div className='item'>{cellValues["name"]}</div>
        <div className='item'>{cellValues["phone"]}</div>
      </div>
      <div className='controls'>
        <div className='item'
          onClick={(e)=>{
            handleViewCustomer(cellValues);
            e.stopPropagation();
          }}
        >{t("Customer Details")}</div>
        <div className='item'
            onClick={(e)=>{
              handleViewCustomerBlockCategory(cellValues);
              e.stopPropagation();
            }}
        >
          {t("Block List Management")}
          </div>
        <div className='item'
          onClick={
            () => props.handleDeleteCustomer(cellValues)
          } 
        >{t("Delete customer")}</div>
      </div>
    </>;
  }

  const handleWrapperClick = (e) => {
    if(e.target.closest(".body") !== null 
      || e.target.closest(".header") !== null
      || e.target.closest(".expansions-wrapper") !== null
      ){
      return;
    }else{
      setExpandedRowIds([]);
    }
  }

  return (  
    <div className="customers-wrapper"
    onClick={handleWrapperClick}
    >
        <div className='grid-wrapper'>
          <div className='search-wrapper'>
              <Select
                size="small"
                value={props.searchParameter.field}
                onChange={(e) => props.changeSearchParameter(e, 'field')}
                className="field"
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              >
                <MenuItem value="name">{t("Customer name")}</MenuItem>
                <MenuItem value="number">{t("Number")}</MenuItem>
                <MenuItem value="lineId">{t("Line ID")}</MenuItem>
                <MenuItem value="lineName">{t("Line Use Name")}</MenuItem>
              </Select>
              <input 
                type="text" 
                className="search" 
                id="search-value" 
                placeholder={t("Search")}
                value={props.searchParameter.value} 
                onChange={(e) => props.changeSearchParameter(e, 'value')} />
              <img src="design-images/search.svg" className='button' onClick={(e) => props.changeSearchParameter(e, 'value')} />
          </div>
          <div className='title'>{t('Customers connected to the community')}</div>
          <MobileDataGrid 
                rowCount={props.rowCount}
                rows={props.rows}
                columns={columns}
                loading={props.loading}
                mainColumn="name"
                page={props.page}
                pageSize={props.pageSize}
                onPageChange={(newPage) => {
                  props.setPage(newPage);
                }}
                visibleExtraColumn="phone"
                renderRowExpansion={renderRowExpansion}
                expandedRowIds={expandedRowIds}
                setExpandedRowIds={setExpandedRowIds}
                />
        </div>
    </div>
  );
}
