import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION, WATCH_STATUS_ON, WATCH_STATUS_OPEN, ALL, WATCH_STATUS_CALLS_IN, WATCH_TYPE_CALL, WATCH_TYPE_SMS } from '../../Constants';
import { WATCH_STATUS_CALLS_OUT, WATCH_STATUS_CLOSED } from '../../Constants';
import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
import { handleApiErrorMessage } from "../../Helper"
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import '../../styles.css';
import Switch from "../Switch";
import Select from "../Select";
import Chip from "../Chip";
import WatchSystemDesktop from "./Desktop";
import WatchSystemMobile from "./Mobile";
import { Backdrop, CircularProgress} from '@mui/material';

export default function WatchSystem(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [watchStatus, setWatchStatus] = useState(WATCH_STATUS_ON);
  const [watchType, setWatchType] = useState(WATCH_TYPE_CALL);
  const [displayWatchTypeSwitch, setDisplayWatchTypeSwitch] = useState(false);

  let initialWatchProfile = {};
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    initialWatchProfile['' + dayIndex] = {};
    for (let hourIndex = 0; hourIndex < 24; hourIndex++) {
      initialWatchProfile['' + dayIndex]['' + hourIndex] = {};
      initialWatchProfile['' + dayIndex]['' + hourIndex]['status'] = WATCH_STATUS_OPEN;
      initialWatchProfile['' + dayIndex]['' + hourIndex]['calls'] = ALL;
    }
  }
  const [watchProfile, setWatchProfile] = useState(null);
  const [excludeNumbers, setExcludeNumbers] = useState([]);
  const [collpasedHour, setCollpasedHour] = useState(null);

  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const [nextSelectedDayIndex, setNextSelectedDayIndex] = useState(0);
  const [addNumberInputVisible, setAddNumberInputVisible] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [isWatchDataModified, setIsWatchDataModified] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const defaultErrorMessage = t(DEFAULT_ERROR_TEXT);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const defaultWarningMessageTitle = t("Warning! There has been errors in your profile, therefore the system saved the closest valid profile.");
  const [warningMessageTitle, setWarningMessageTitle] = useState(defaultWarningMessageTitle);
  const [warningMessageErrors, setWarningMessageErrors] = useState([]);

  const [shouldDisplayApplyModificationsPopup, setShouldDisplayApplyModificationsPopup] = useState(false);
  const [applyWatchSettingsDialogIsOpen, setApplyWatchSettingsDialogIsOpen] = useState(false);
  const [selectedDaysToApplyChanges, setSelectedDaysToApplyChanges] = useState([]);

  const dayNumberToTitle = {
    "0": t("Sunday"),
    "1": t("Monday"),
    "2": t("Tuesday"),
    "3": t("Wednesday"),
    "4": t("Thursday"),
    "5": t("Friday"),
    "6": t("Saturday")
  };

  useEffect(() => {
    if (typeof props.entityType === 'undefined') {
      return () => { };
    }

    let headers = {
      token: cookie.get('token')
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let apiUrl = API_URL;
    if (props.entityType === "community") {
      apiUrl += `/getWatchProfile`;
    } else if (props.entityType === "customer") {
      apiUrl += `/by_customer/${props.customerId}/getWatchProfile`;
    } else if (props.entityType === "line") {
      apiUrl += `/by_line/${props.customerId}/${props.lineId}/getWatchProfile`;
    }

    let params = {
      service: WATCH_TYPE_CALL
    };
    if(watchType === WATCH_TYPE_SMS){
      params.service = WATCH_TYPE_SMS;
    }

    if (props.entityType === "community") {
      axios.get(API_URL + '/getWatchStatus', {headers, params})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data['responseStatus'] === API_OK) && data.hasOwnProperty("status")){
            setWatchStatus(data.status);
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      });
    }

    axios.get(apiUrl, { headers, params })
      .then(
        ({ data }) => {
          if (data.hasOwnProperty("responseStatus") && (data['responseStatus'] === API_OK)) {
            if (props.entityType !== "community") {
              setWatchStatus(data.status);
            }
            if (data.profile !== null) {
              setWatchProfile(data.profile);
            } else {
              setWatchProfile(initialWatchProfile);
            }
            if (data.exclude_numbers !== null) {
              setExcludeNumbers(data.exclude_numbers);
            }
          } else {
            if ((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))) {
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        });
  }, [props.entityType, watchType]);


  useEffect(() => {
    if(isWatchDataModified === true){
      handleSave();
    }
  }, [isWatchDataModified]);

  useEffect(() => {
    setDisplayWatchTypeSwitch(cookie.get('smsWatchSystemAllow') === "true" ? true : false);
  }, []);

  const handleDeleteExcludedNumber = (numberIndex) => {
    let newExcludedNumbers = [...excludeNumbers];
    newExcludedNumbers.splice(numberIndex, 1);
    setExcludeNumbers(newExcludedNumbers);
    setIsWatchDataModified(true);
  };

  const getExcludedNumbersChips = () => {
    if(excludeNumbers === null){
      return [];
    }
    let numberChips = [];
    for(let numberIndex = 0; numberIndex < excludeNumbers.length; numberIndex++){
      numberChips.push(
        <Chip label={excludeNumbers[numberIndex]} key={excludeNumbers[numberIndex]} onDelete={() => handleDeleteExcludedNumber(numberIndex)} />
      );
    }
    return numberChips;
  }

  const handleAddExcludedNumber = () => {
      let inputValue = document.getElementById("excluded_numbers").value;
      if(inputValue === ''){
        return;
      }
      var numericValueRegex = /^\*?\d+$/;
      if(!numericValueRegex.test(inputValue)){
        alert(t("Please insert only numeric values"));
        return;
      }

      let newExcludeNumbers = [...excludeNumbers];
      newExcludeNumbers.push(inputValue);
      setExcludeNumbers(newExcludeNumbers);

      document.getElementById("excluded_numbers").value = "";

      setIsWatchDataModified(true);
  };

  const displayFadingSuccessMessage = () => {
    setShowSuccessMessage(true);
    setTimeout(function (){
      setShowSuccessMessage(false);
  }, 1000);
  }

  const  handleSave = async () => {
    setLoaderVisible(true);

    setShowWarningMessage(false);
    setShowErrorMessage(false);

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL;

    let params = {
      service: WATCH_TYPE_CALL
    };
    
    if(watchType === WATCH_TYPE_SMS){
      params.service = WATCH_TYPE_SMS;
    }

    if(props.entityType === "community"){
      let isUpdateStatusSuccessful = false;
      let isUpdateProfileSuccessful = false;

      apiUrl = API_URL + '/setWatchStatus';
      params = { ...params, status: watchStatus, exclude_numbers: excludeNumbers };
      await axios.post(apiUrl, params, {headers})
        .then(
          ({ data }) => {
            if(data.hasOwnProperty("responseStatus") && (data['responseStatus'] === API_OK)){
              isUpdateStatusSuccessful = true;
            }else{
              if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
                errorMessage = data["message"];
              }
              setErrorMessage(errorMessage);
              setShowErrorMessage(true);
              setIsWatchDataModified(false);
            }
          }).catch((error) => {
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            setIsWatchDataModified(false);
        });

        apiUrl = API_URL + `/setWatchProfile`;

        params = { ...params, profile: watchProfile };

        await axios.put(apiUrl, params, { headers })
        .then(
          async (responseData) => {
            if (responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK) {
              if (responseData.data.checkResponse.status === "OK") {
                setLoaderVisible(false);
                isUpdateProfileSuccessful = true;
              } else {
                if (responseData.data.checkResponse.status === "FIX") {
                  setWarningMessageErrors(responseData.data.checkResponse.problems);
                  setLoaderVisible(false);
                  setShowWarningMessage(true);
                }
                if (responseData.data.checkResponse.status === "MIS" || responseData.data.checkResponse.status === "ERROR") {
                  setLoaderVisible(false);
                  setShowErrorMessage(true);
                }
                setWatchProfile(responseData.data.checkResponse.profile);
              }
            } else {
              if ((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))) {
                errorMessage = responseData.data["message"];
              }
              setErrorMessage(errorMessage);
              setShowErrorMessage(true);
              setLoaderVisible(false);
            }
            setIsWatchDataModified(false);
          }).catch((error) => {
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            setLoaderVisible(false);
            setIsWatchDataModified(false);
          });

          if(isUpdateStatusSuccessful && isUpdateProfileSuccessful){
            displayFadingSuccessMessage(true);
          }
    }else{
      params = { ...params, status: watchStatus, profile: watchProfile, exclude_numbers: excludeNumbers };
      apiUrl = API_URL;

      if (props.entityType === "customer") {
        apiUrl += `/by_customer/${props.customerId}/setWatchProfile`;
      } else if (props.entityType === "line") {
        apiUrl += `/by_line/${props.customerId}/${props.lineId}/setWatchProfile`;
      }

      await axios.put(apiUrl, params, { headers })
      .then(
        async (responseData) => {
          if (responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK) {
            if (responseData.data.response.valid_profile_status === "OK") {
              setLoaderVisible(false);
              displayFadingSuccessMessage(true);
            } else {  
              if (responseData.data.response.valid_profile_status === "FIX") {
                setWarningMessageErrors(responseData.data.response.valid_profile_problems);
                setLoaderVisible(false);
                setShowWarningMessage(true);
              }
              if (responseData.data.response.valid_profile_status === "MIS" || responseData.data.response.valid_profile_status === "ERROR") {
                setLoaderVisible(false);
                setShowErrorMessage(true);
              }
              setWatchProfile(responseData.data.response.profile);
              setWatchStatus(responseData.data.response.status);
            }
          } else {
            if ((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))) {
              errorMessage = responseData.data["message"];
            }
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            setLoaderVisible(false);
          }
          setIsWatchDataModified(false);
        }).catch((error) => {
          setErrorMessage(errorMessage);
          setShowErrorMessage(true);
          setLoaderVisible(false);
        });

    }
  }

  const formatHour = (hourIndex) => {
    if (hourIndex < 10) {
      return '0' + hourIndex + ':00';
    } else {
      return hourIndex + ':00';
    }
  }

  const changeWatchProfile = (value, id, modifiedDayIndex, modifiedHourIndex) => {
    setShowSuccessMessage(false);
    let newWatchProfile = Object.assign({}, watchProfile);
    newWatchProfile[modifiedDayIndex][modifiedHourIndex][id] = value;
    setWatchProfile(newWatchProfile);
    setIsWatchDataModified(true);
    setShouldDisplayApplyModificationsPopup(true);
  }

  const displayApplyPopupAndRememberNextSelectedDay = (dayIndex) => {
    setNextSelectedDayIndex(dayIndex);
    setApplyWatchSettingsDialogIsOpen(true);
    setShouldDisplayApplyModificationsPopup(false);
  }

  const getDayLabels = (letterOnly = false) => {
      let dayLabels = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        dayLabels.push(<div 
          key={'day'+dayIndex}
          className={'day'+(dayIndex === selectedDayIndex ? ' selected' : '')} 
          data-index={dayIndex}
          onClick={()=>{
            if(selectedDayIndex !== dayIndex
                && shouldDisplayApplyModificationsPopup === true){
                if(!selectedDaysToApplyChanges.includes(dayIndex)){
                  selectedDaysToApplyChanges.push(dayIndex);
                }
                displayApplyPopupAndRememberNextSelectedDay(dayIndex);
              }else{
                setSelectedDayIndex(dayIndex);
              }
          }}
          >{letterOnly ? t("Day Letter "+dayIndex) : t(dayNumberToTitle['' + dayIndex])}</div>)
      }
      return dayLabels;
  }

  const getDayLines = (dayIndex, hourIndexStart, hourIndexEnd, isAllCollpased = true) => {
    let dayLines = [];
    for (let hourIndex = hourIndexStart; hourIndex <= hourIndexEnd; hourIndex++) {
      dayLines.push(
        <div className="hour-wrapper" 
          key={'wrapper-day-hour-' + dayIndex + '-' + hourIndex}>
          <div
            onClick={(e) => {
              if(collpasedHour !== hourIndex){
                setCollpasedHour(hourIndex);
                e.stopPropagation();
              }else{
                setCollpasedHour(null);
              }
            }}
            >{formatHour(hourIndex)}</div>
          {(
            isAllCollpased === true || collpasedHour === hourIndex ?
            <>
            <Select values={{
                [ALL]: t("All"),
                [WATCH_STATUS_CALLS_OUT]: t("Watch outcoming calls"),
                [WATCH_STATUS_CALLS_IN]: t("Watch incoming calls")
              }} 
                value={watchProfile === null ? ALL : watchProfile[dayIndex][hourIndex]['calls']}
                handleClick={(newValue, e) => {
                  changeWatchProfile(newValue, "calls", dayIndex, hourIndex);
                  e.stopPropagation();
                }}
                />
            <Switch 
              type="text"
              name="status"
              values={{
                [WATCH_STATUS_CLOSED]: t("Watch Inactive"),
                [WATCH_STATUS_OPEN]: t("Watch Active") 
              }}
              value={watchProfile === null ? WATCH_STATUS_OPEN : watchProfile[dayIndex][hourIndex]['status']}
              mainValue={WATCH_STATUS_OPEN}
              handleClick={(newValue,e ) => {
                changeWatchProfile(newValue, "status", dayIndex, hourIndex);
                e.stopPropagation();
              }}
              />
            </>
             : ''
          )}
        </div>);
    }
    return dayLines;
  }

  const warningMessageErrorLines = () => {
    let errorLines = [];
    for (let errorIndex = 0; errorIndex < warningMessageErrors.length; errorIndex++) {
      errorLines.push(<li className='warning-message-error' key={'warning-message-error-' + errorIndex}>{warningMessageErrors[errorIndex]}</li>);
    }
    return errorLines;
  };

  const getApplyDays = () => {
    let applyDays = [];
    for(let index = 0; index < 7; index++){
      applyDays.push(<div 
        key={'apply-day-'+index}
        className={'day'+(selectedDaysToApplyChanges.includes(index) || index === selectedDayIndex ? ' selected':'')}
        onClick={()=>{
          if(selectedDaysToApplyChanges.includes(index) && index !== selectedDayIndex){
            var indexKey = selectedDaysToApplyChanges.indexOf(index);
            if (indexKey !== -1) {
              let newSelectedDaysToApplyChanges = JSON.parse(JSON.stringify(selectedDaysToApplyChanges));
              newSelectedDaysToApplyChanges.splice(indexKey, 1);
              setSelectedDaysToApplyChanges(newSelectedDaysToApplyChanges);
            }
          }else{
            setSelectedDaysToApplyChanges([...selectedDaysToApplyChanges, index]);
          }
        }}
      >
        {t('Day Letter '+index)}
      </div>);
    }
    return applyDays
  }

  const applyProfileChangesOnSelectedDays = () => {
    if(selectedDaysToApplyChanges.length > 0){
      let newWatchProfile = watchProfile;
      for(let index = 0; index < 7; index++){
        if(selectedDaysToApplyChanges.includes(index)){
          newWatchProfile[index] = {...watchProfile[selectedDayIndex]};
        }
      }
      setWatchProfile(newWatchProfile);
      setIsWatchDataModified(true);
      setApplyWatchSettingsDialogIsOpen(false);
      setSelectedDaysToApplyChanges([]);
      setSelectedDayIndex(nextSelectedDayIndex);
    }
  }

  return (<>
      <div className={'watch-wrapper '+(props.isDesktop === true ? ' desktop' : ' mobile')+(props.watchStatus === WATCH_STATUS_ON ? ' on' : ' off')}
        onClick={(e) => {
          setCollpasedHour(null);
        }}
      >
        {(
          props.isDesktop ? 
          <WatchSystemDesktop
            showSuccessMessage={showSuccessMessage}
            errorMessage={errorMessage}
            showErrorMessage={showErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
            warningMessageErrorLines={warningMessageErrorLines}
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
            warningMessageTitle={warningMessageTitle}
            setWatchStatus={setWatchStatus}
            watchStatus={watchStatus}
            setWatchType={setWatchType}
            watchType={watchType}
            displayWatchTypeSwitch={displayWatchTypeSwitch}
            setIsWatchDataModified={setIsWatchDataModified}
            getDayLabels={getDayLabels}
            getExcludedNumbersChips={getExcludedNumbersChips}
            addNumberInputVisible={addNumberInputVisible}
            setAddNumberInputVisible={setAddNumberInputVisible}
            handleAddExcludedNumber={handleAddExcludedNumber}
            applyWatchSettingsDialogIsOpen={applyWatchSettingsDialogIsOpen}
            getApplyDays={getApplyDays}
            applyProfileChangesOnSelectedDays={applyProfileChangesOnSelectedDays}
            setApplyWatchSettingsDialogIsOpen={setApplyWatchSettingsDialogIsOpen}
            selectedDayIndex={selectedDayIndex}
            nextSelectedDayIndex={nextSelectedDayIndex}
            setSelectedDayIndex={setSelectedDayIndex}
            getDayLines={getDayLines}
          /> :
          <WatchSystemMobile 
            showSuccessMessage={showSuccessMessage}
            errorMessage={errorMessage}
            showErrorMessage={showErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
            warningMessageErrorLines={warningMessageErrorLines}
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
            warningMessageTitle={warningMessageTitle}
            setWatchStatus={setWatchStatus}
            watchStatus={watchStatus}
            setWatchType={setWatchType}
            watchType={watchType}
            displayWatchTypeSwitch={displayWatchTypeSwitch}
            setIsWatchDataModified={setIsWatchDataModified}
            getDayLabels={getDayLabels}
            getExcludedNumbersChips={getExcludedNumbersChips}
            addNumberInputVisible={addNumberInputVisible}
            setAddNumberInputVisible={setAddNumberInputVisible}
            handleAddExcludedNumber={handleAddExcludedNumber}
            applyWatchSettingsDialogIsOpen={applyWatchSettingsDialogIsOpen}
            getApplyDays={getApplyDays}
            applyProfileChangesOnSelectedDays={applyProfileChangesOnSelectedDays}
            setApplyWatchSettingsDialogIsOpen={setApplyWatchSettingsDialogIsOpen}
            selectedDayIndex={selectedDayIndex}
            nextSelectedDayIndex={nextSelectedDayIndex}
            setSelectedDayIndex={setSelectedDayIndex}
            getDayLines={getDayLines}
          />
        )}
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 9999}}
        open={loaderVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
  </>
  );
}
